import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { bookingResponse, bookingDetailsResponse, exportOrdersCSVResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'

const bookings = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi(bookingResponse) : apiHandler.getRequest('bookings', data)

const booking = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi(bookingDetailsResponse) : apiHandler.getRequest('booking', data)

const updateBookingInfo = (bookingData = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(bookingDetailsResponse)
    : apiHandler.postRequest('updateBookingInfo', { data: bookingData })

const exportBookings = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi(exportOrdersCSVResponse) : apiHandler.getRequest('exportBookings', data)

const bookingsService = {
  bookings,
  booking,
  updateBookingInfo,
  exportBookings,
}

export default bookingsService
