import { Domain, Module } from 'redux/Auth/auth.state'

export interface AppState {
  sideBarShow: boolean
  lookup: LookUpInfo
}

export const initialState: AppState = {
  sideBarShow: true,
  lookup: {
    domains: [],
    modules: [],
  },
}

export interface LookUpInfo {
  domains: Domain[]
  modules: Module[]
}
