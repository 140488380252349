import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

export const selectAuthToken = (state: RootState) => state.authUser.token
export const selectUser = (state: RootState) => state.authUser.user
export const selectDomains = createSelector(selectUser, (user) => user?.userDomains ?? [])
export const selectPermissions = createSelector(selectUser, (user) => user?.permissions ?? [])
export const selectPermittedModules = createSelector(selectPermissions, (permissions) =>
  permissions.filter((permission) => permission.read || permission.write || permission.delete),
)
