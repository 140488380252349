/**
 * This function merges two object with same keys
 * @param obj1 Object
 * @param obj2 Object
 */
export function mergeObject(object1, object2) {
  const object1Keys = Object.keys(object1)
  const object2Keys = Object.keys(object2)

  const mergedObject = {}

  for (const key of object1Keys) {
    const object1Data = object1[key]
    const object2Data = object2[key]

    if (Array.isArray(object1Data))
      mergedObject[key] = [...object1Data, ...(object2Data ?? [])]
    else if (typeof object1Data === 'object')
      mergedObject[key] = {
        ...object1Data,
        ...object2Data,
      }
    else mergedObject[key] = object2Data ?? object1Data
  }

  for (const key of object2Keys) {
    if (!mergedObject[key]) mergedObject[key] = object2[key]
  }

  return mergedObject
}
