import { Box, SxProps } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { COLORS } from 'styles'
import { CalendarDateRangeType } from 'components/Forms/DateRangePicker'
import { subDays } from 'date-fns'
import { Chart, ChartRef, WidgetCard } from 'components/UI'
import { topPieStyles } from './TopPie'
import { areaPiAreaChartStyles } from './AreaChart'

interface BarChartProps {
  titleText: string
  data: {
    x_axis: string
    y_axis: number
  }[]
  chartHeight: string
  currency?: boolean
}

export default function BarChart({
  titleText,
  data,
  chartHeight,
}: BarChartProps) {
  const [chartData, setChartData] = useState({
    ...options,
    chart: {
      type: 'column',
      height: chartHeight ?? '250px',
    },
  })
  const chartComponent = useRef<ChartRef>()

  const [dateRange] = useState<CalendarDateRangeType>([
    subDays(new Date(), 6),
    new Date(),
  ])

  useEffect(() => {
    if (data) {
      setChartData({
        ...options,
        chart: {
          type: 'column',
          height: chartHeight ?? '250px',
        },
        xAxis: {
          ...options.xAxis,
          categories: data?.map((value) => value.x_axis),
        },
        series: [
          {
            name: titleText,
            color: COLORS.PRIMARY,
            data: data?.map((value) => Math.round(value.y_axis)),
          },
        ],
      })
      chartComponent?.current?.chart.reflow()
    }
  }, [chartHeight, data, titleText])

  useEffect(() => {
    // call api here
  }, [dateRange])

  return (
    <WidgetCard
      title={titleText}
      // dateRangeProps={{
      //   value: dateRange,
      //   onChange: (range) => {
      //     setDateRange(range)
      //   },
      //   disableFutureDates: true,
      // }}
      // enableDateRange
    >
      {/* Chart  */}
      <Box
        sx={
          [
            topPieStyles.chartWrapper,
            areaPiAreaChartStyles.chartWrapper,
          ] as SxProps
        }
      >
        <Box sx={areaPiAreaChartStyles.chartSizeWrapper}>
          <Chart ref={chartComponent} options={{ ...chartData }} />
        </Box>
      </Box>
    </WidgetCard>
  )
}

const options = {
  chart: {
    type: 'column',
    height: '250px',
  },
  title: {
    text: '',
  },
  credits: false,

  xAxis: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    crosshair: false,
    lineColor: 'transparent',
    tickLength: 0,
  },
  yAxis: {
    visible: false,
  },
  tooltip: {
    enabled: true,
    formatter(this) {
      return `<b>${this.x}</b>: ${this.y}
      `
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.1,
      borderWidth: 0,
      borderRadius: 5,

      dataLabels: {
        enabled: true,
        useHTML: true,
        format: '<span>{point.y}</span>',
      },
    },
  },
  series: [
    {
      name: 'New Users',
      color: COLORS.PRIMARY,
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
}
