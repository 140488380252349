import { API_URL } from 'helpers/Constants'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { store } from 'redux/store'
import authActions from 'redux/Auth/auth.action'
// import { enqueueSnackbar } from 'notistack'

interface Response<ResponseData extends object> {
  message: string
  time?: number
  data: ResponseData
}

const setHeaders = () => {
  const authToken = localStorage.getItem('token')
  const headers = {
    'Content-Type': 'application/json',
  }
  if (authToken !== '') {
    // eslint-disable-next-line dot-notation
    headers['Authorization'] = `Bearer ${authToken}`
  }

  return headers
}

const axiosRequest = async <ResponseType extends object>(
  method: string,
  url: string,
  data: AxiosRequestConfig = {},
) => {
  const config: AxiosRequestConfig = {
    method,
    baseURL: API_URL,
    url,
    headers: { ...setHeaders(), ...data.headers },
    ...data,
  }

  try {
    const response: AxiosResponse<Response<ResponseType>> = await axios(config)

    return { status: response.status, data: response.data, response: response.data }
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error

    const { response } = error

    if (response.status === 401) {
      // Handle unauthorized error
      store.dispatch(authActions.userLogout())
    } else throw response
  }
}

const getRequest = <ResponseType extends object>(url: string, data?: AxiosRequestConfig) =>
  axiosRequest<ResponseType>('GET', url, data)

const postRequest = <ResponseType extends object>(url: string, data?: AxiosRequestConfig) =>
  axiosRequest<ResponseType>('POST', url, data)

const putRequest = <ResponseType extends object>(url: string, data?: AxiosRequestConfig) =>
  axiosRequest<ResponseType>('PUT', url, data)

const deleteRequest = <ResponseType extends object>(url: string, data?: AxiosRequestConfig) =>
  axiosRequest<ResponseType>('DELETE', url, data)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mockApi = async <ResponseType extends object>(data: any) =>
  data as { status: number; data: Response<ResponseType>; response: Response<ResponseType> }

const apiHandler = {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  mockApi,
}

export default apiHandler
