/* eslint-disable unicorn/filename-case */
import useToast from 'components/UI/Toast'
import { useCallback, useEffect, useState } from 'react'
import { User } from 'redux/Auth/auth.state'
import { crmUsersService } from 'services'
import { UserListParams } from 'services/crmUsers.types'

export const useCrmUserListData = () => {
  const [list, setList] = useState<User[]>([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const getUserList = useCallback(
    async (params: UserListParams) => {
      setIsLoading(true)
      try {
        const { response } = await crmUsersService.crmUserList(params)
        setList(response.data.users)
        setCount(response.data.count)
      } catch (error) {
        toast.error(error?.data?.message)
      } finally {
        setIsLoading(false)
      }
    },
    [toast],
  )

  useEffect(() => {
    getUserList({ currentPage: 1 })
  }, [getUserList])

  return {
    list,
    setList,
    getUserList,
    count,
    isLoading,
  }
}
