import { Chip, Table, WidgetCard } from 'components/UI'
import { BOOKING_STATUS_COLOR } from 'helpers/Constants'
import { Select } from 'components/Forms'
import { useState, useMemo, useEffect } from 'react'
import { currencyFormatter } from 'helpers/Methods/utils'
import { TodaysBookings } from '../Dashboard'

interface BookingsProps {
  todayBookings?: TodaysBookings[]
  yesterdayBookings?: TodaysBookings[]
  dayBeforeYesterdayBookings?: TodaysBookings[]
}

const durationValues = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Day before Yesterday',
    value: 'dayBeforeYesterday',
  },
]

export default function Bookings({
  todayBookings,
  yesterdayBookings,
  dayBeforeYesterdayBookings,
}: BookingsProps) {
  const [durationValue, setDurationValue] = useState('today')
  const [bookingData, setBookingData] = useState(todayBookings ?? [])

  useEffect(() => {
    setBookingData(todayBookings)
  }, [todayBookings, yesterdayBookings, dayBeforeYesterdayBookings])

  const onDurationChange = (event) => {
    setDurationValue(event.target.value)
    switch (event.target.value) {
      case 'today': {
        setBookingData(todayBookings)

        break
      }
      case 'yesterday': {
        setBookingData(yesterdayBookings)

        break
      }
      case 'dayBeforeYesterday': {
        setBookingData(dayBeforeYesterdayBookings)

        break
      }
      // No default
    }
  }
  const currentDuration = useMemo(
    () =>
      durationValues.find((duration) => duration.value === durationValue) ??
      durationValues[0],
    [durationValue],
  )

  return (
    <WidgetCard
      title="Recent Orders"
      subTitle={`Order received ${currentDuration.label}`}
      rightComponent={
        <Select
          data={durationValues}
          value={durationValue}
          size="small"
          variant="filled"
          onChange={onDurationChange}
          sx={{
            minWidth: 100,
          }}
        />
      }
    >
      <Table
        containerProps={{
          sx: {
            mt: 2,
          },
        }}
        columns={[
          { name: '#Order', key: 'bookingNum' },
          { name: 'Name', key: 'name' },
          {
            name: 'Amount($)',
            key: 'price',
            render: (value) => currencyFormatter(value),
          },
          {
            name: 'Status',
            key: 'status',
            render: (value) => (
              <Chip
                size="small"
                label={value}
                color={BOOKING_STATUS_COLOR[value]}
                borderLess
              />
            ),
          },
        ]}
        data={bookingData}
      />
    </WidgetCard>
  )
}
