/* eslint-disable sonarjs/deprecation */
/* eslint-disable sonarjs/cognitive-complexity */
import { Box, Grid } from '@mui/material'
import { Card, CardSubTitle, PageTitle } from 'components/UI'
import { useCallback, useEffect, useState } from 'react'
import {
  CalendarMonth as CalendarMonthIcon,
  Receipt as ReceiptIcon,
  Person as PersonIcon,
  Email as EmailIcon,
  PhoneIphone as PhoneIphoneIcon,
  Home as HomeIcon,
  LocationCity as LocationCityIcon,
  FmdGood as FmdGoodIcon,
  Public as PublicIcon,
  Event as EventIcon,
  Stadium as StadiumIcon,
  DateRange as DateRangeIcon,
  BookOnline as BookOnlineIcon,
  Numbers as NumbersIcon,
  EventSeat as EventSeatIcon,
  AttachMoney as AttachMoneyIcon,
  Hotel as HotelIcon,
  KingBed as KingBedIcon,
  AirplaneTicket as AirplaneTicketIcon,
  Luggage as LuggageIcon,
  FlightTakeoff as FlightTakeoffIcon,
  MyLocation as MyLocationIcon,
  Star as StarIcon,
  Reviews as ReviewsIcon,
  HighlightOff as HighLightOffIcon,
  RoomPreferences as RoomPreferencesIcon,
  Grid3x3 as Gird3x3Icon,
  Link as LinkIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Subtitles as SubtitlesIcon,
  Title as TitleIcon,
} from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import { bookingsService } from 'services'
import { ORDER_UPDATE } from 'helpers/Constants/messages'
import { currencyFormatter, formatNumber } from 'helpers/Methods/utils'
import { BOOKING_STATUS } from 'helpers/Constants'
import { FlightWidget, WidgetCard } from './Widgets'
import useToast from 'components/UI/Toast'

export type flightObject = {
  arrivalAirport: string
  arrivalDate: string
  arrivalLocation: string
  arrivalTime: string
  departDate: string
  departTime: string
  departureAriport: string
  departureLocation: string
  operatedBy: string
  seats: string
  travelTime: number
  flightNumber: string
  flightClass: string
  stops: string
  class_type: string
  farebasiscode: string
  connectionCity1: string
  connectionCity2: string
}
interface BookingDataObject {
  bookingNumber: string
  orderDate: string
  daysbetween: string | number
  bookingStatus: string | number
  contact: {
    address: string
    city: string
    country: string
    email: string
    first_name: string
    last_name: string
    name: string
    phone: string
    postalCode: string
    province: string
  }
  event?: {
    date: string
    location: string
    name: string
    venue_latitude?: string
    venue_longitude?: string
    location_city: string
    venue_id: number | string
    venue_country: string
  }
  ticket?: {
    bookingNumber: string | number
    delivery_date: string
    event_name: string
    chargeRate: string | number
    hstServiceChargeBS: string | number
    price_per_ticket: string
    quantity: string | number
    row: string
    section: string
    serviceChargeBS: string | number
    subTotal: number
    taxes: number
    total: number
    type: string | number
  }
  hotel?: {
    address: string
    bookingNumber: string | number
    chargeRate: string | number
    checkIn: string
    checkOut: string
    hstServiceChargeBS: string | number
    name: string
    roomCategory: string
    serviceChargeBS: string
    subTotal: string | number
    taxes: string | number
    commission?: string | number
    total: string | number
    totalTaxesFees: string | number
    tripNumber: string | number
    num_Rooms?: string | number
    star_rating?: string | number
    review_rating?: string | number
    cancellation?: string | number
    nights?: string | number
    amenities?: string
    adults?: string | number
    children?: string | number
    supplier?: string
  }
  flight: {
    bookingNumber: string
    departure: flightObject
    hstServiceChargeBS: string
    passengers: { birthDate: string; name: string }[]
    return: flightObject
    serviceChargeBS: string
    subTotal: string
    taxes: string
    taxesFees: string
    total: string
  }
  payment?: {
    baseTotal: number
    currency: string
    hstServiceChargeBS: number
    promoCode: string
    savings: number
    serviceChargeBS: number
    subTotal: number
    taxes: number
    total: number
    totalTaxesFees: number
  }
  insurance?: {
    bookingNumber: string
    insuranceBSServiceFees: number
    insuranceHSTTax: number
    quoteId: string
    refundableLink: string
    subTotal: number
    taxes: number
    total: number
    totalTaxesFees: number
  }
  addOns?: {
    quantity: number
    chargeRate: number
    costPer: number
    subTotal: number
    taxes: number
    total: number
    serviceChargeBS: number
    hstServiceChargeBS: number
    totalTaxesFees: number
    itemName: string
    itemDescriptionShort: string
  }
}
export default function ViewOrder() {
  const [searchParams] = useSearchParams()
  const bookingId = searchParams.get('id')
  const [bookingData, setBookingData] = useState<BookingDataObject>()
  const [hotelEditState, setHotelEditState] = useState(false)
  const [ticketEditState, setTicketEditState] = useState(false)
  const [orderEditState, setOrderEditState] = useState(false)
  const [insuranceEditState, setInsuranceEditState] = useState(false)
  const [addOnsEditState, setAddOnsEditState] = useState(false)
  const [flightEditState, setFlightEditState] = useState(false)
  const [paymentEditState, setPaymentEditState] = useState(false)
  const [contactEditState, setContactEditState] = useState(false)
  const [billingEditState, setBillingEditState] = useState(false)
  const toast = useToast()

  const bookingStatusValue = [
    {
      label: 'Created',
      value: 1,
    },
    {
      label: 'Pending',
      value: 2,
    },
    {
      label: 'Shipped',
      value: 3,
    },
    {
      label: 'Processed',
      value: 4,
    },
    {
      label: 'Error',
      value: 5,
    },
  ]

  const getBookingDetails = useCallback(async () => {
    const response = await bookingsService.booking({
      params: {
        bookingId,
      },
    })
    if (response) {
      //@ts-ignore
      setBookingData(response.data)
    }
  }, [bookingId])

  const edit = (product) => {
    switch (product) {
      case 'hotel': {
        setHotelEditState(true)

        break
      }
      case 'ticket': {
        setTicketEditState(true)

        break
      }
      case 'flight': {
        setFlightEditState(true)

        break
      }
      case 'order': {
        setOrderEditState(true)

        break
      }
      case 'insurance': {
        setInsuranceEditState(true)

        break
      }
      case 'addOns': {
        setAddOnsEditState(true)

        break
      }
      case 'payment': {
        setPaymentEditState(true)

        break
      }
      case 'contact': {
        setContactEditState(true)

        break
      }
      case 'billing': {
        setBillingEditState(true)

        break
      }
      // No default
    }
  }

  const save = async (product) => {
    let data: Record<string, number | string | flightObject | Array<Record<string, string | number>>> = {
      product,
      bookingID: bookingId,
    }
    switch (product) {
      case 'hotel': {
        setHotelEditState(false)
        data = { ...bookingData.hotel, ...data }

        break
      }
      case 'flight': {
        setFlightEditState(false)
        data = {
          ...bookingData.flight,
          ...data,
        }

        break
      }
      case 'ticket': {
        setTicketEditState(false)
        data = {
          ...bookingData.ticket,
          ...data,
        }

        break
      }
      case 'insurance': {
        setInsuranceEditState(false)
        data = {
          ...bookingData.insurance,
          ...data,
        }

        break
      }
      case 'addOns': {
        setAddOnsEditState(false)
        data = {
          ...bookingData.addOns,
          ...data,
        }

        break
      }
      case 'order': {
        setOrderEditState(false)
        data = {
          bookingStatus: bookingData.bookingStatus,
          // orderDate: bookingData.orderDate,
          ...data,
        }

        break
      }
      case 'payment': {
        setPaymentEditState(false)
        data = {
          ...bookingData.payment,
          ...data,
        }

        break
      }
      case 'contact': {
        setContactEditState(false)
        data = {
          ...bookingData.contact,
          ...data,
        }

        break
      }
      case 'billing': {
        setBillingEditState(false)
        data = {
          ...bookingData.contact,
          ...data,
        }

        break
      }
      // No default
    }
    try {
      const response = await bookingsService.updateBookingInfo(data)
      if (response) {
        toast.success(ORDER_UPDATE)
      }
    } catch {
      toast.error('Error Processing your request!')
    }
  }

  const updateBookingValue = (product: string, field: string, value: BookingDataObject[keyof BookingDataObject]) => {
    const bookingInfo = { ...bookingData }
    switch (product) {
      case 'hotel': {
        bookingInfo[product][field] = value

        break
      }
      case 'ticket': {
        bookingInfo[product][field] = value

        break
      }
      case 'flight': {
        bookingInfo[product][field] = value

        break
      }
      case 'flightPassenger': {
        bookingInfo.flight.passengers[field.split('_')[1]][field.split('_')[0]] = value

        break
      }
      case 'departureFlight': {
        bookingInfo.flight.departure[field] = value

        break
      }
      case 'returnFlight': {
        bookingInfo.flight.return[field] = value

        break
      }
      case 'insurance': {
        bookingInfo[product][field] = value

        break
      }
      case 'addOns': {
        bookingInfo[product][field] = value

        break
      }
      case 'order': {
        bookingInfo[field] = value

        break
      }
      case 'payment': {
        bookingInfo[product][field] = value

        break
      }
      case 'contact':
      case 'billing': {
        bookingInfo.contact[field] = value

        break
      }
      // No default
    }
    setBookingData(bookingInfo)
  }

  useEffect(() => {
    getBookingDetails()
  }, [bookingId, getBookingDetails])

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
              <CardSubTitle label={`${CONTENT_LABELS.orderDetailTitle}`} sx={{ color: '#444444', fontSize: '1rem' }} />
              {orderEditState ? (
                <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('order')} />
              ) : (
                <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('order')} />
              )}
            </Box>
            <WidgetCard title="Order No" value={bookingData?.bookingNumber} Icon={ReceiptIcon} />
            <WidgetCard
              title="Order Date"
              value={bookingData?.orderDate}
              Icon={CalendarMonthIcon}
              // editState={orderEditState}
              // inputType="text"
              // inputField="orderDate"
              // product="order"
              // onChangeValue={updateBookingValue}
            />
            <WidgetCard
              title="Status"
              value={orderEditState ? bookingData?.bookingStatus : BOOKING_STATUS[bookingData?.bookingStatus]}
              Icon={AssignmentTurnedInIcon}
              editState={orderEditState}
              fieldType="select"
              selectOption={bookingStatusValue}
              inputType="text"
              inputField="bookingStatus"
              product="order"
              onChangeValue={updateBookingValue}
              componentWrapperStyles={{ border: 'none' }}
            />
          </Card>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
              <CardSubTitle
                label={`${CONTENT_LABELS.customerDetailTitle}`}
                sx={{ color: '#444444', fontSize: '1rem' }}
              />
              {contactEditState ? (
                <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('contact')} />
              ) : (
                <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('contact')} />
              )}
            </Box>
            <WidgetCard
              title="Customer"
              value={
                contactEditState
                  ? `${bookingData?.contact?.first_name}_${bookingData?.contact?.last_name}`
                  : `${bookingData?.contact?.first_name} ${bookingData?.contact?.last_name}`
              }
              Icon={PersonIcon}
              editState={contactEditState}
              inputType="text"
              inputField="name"
              product="contactName"
              onChangeValue={updateBookingValue}
            />
            <WidgetCard
              title="Email"
              value={bookingData?.contact?.email}
              Icon={EmailIcon}
              editState={contactEditState}
              inputType="email"
              inputField="email"
              product="contact"
              onChangeValue={updateBookingValue}
            />
            <WidgetCard
              title="Phone"
              value={bookingData?.contact?.phone}
              Icon={PhoneIphoneIcon}
              editState={contactEditState}
              inputType="test"
              inputField="phone"
              product="contact"
              onChangeValue={updateBookingValue}
              componentWrapperStyles={{ border: 'none' }}
            />
          </Card>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
              <CardSubTitle
                label={`${CONTENT_LABELS.billingDetailTitle}`}
                sx={{ color: '#444444', fontSize: '1rem' }}
              />
              {billingEditState ? (
                <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('billing')} />
              ) : (
                <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('billing')} />
              )}
            </Box>
            <WidgetCard
              title="Address"
              value={bookingData?.contact?.address}
              Icon={HomeIcon}
              editState={billingEditState}
              inputType="test"
              inputField="address"
              product="contact"
              onChangeValue={updateBookingValue}
            />
            <WidgetCard
              title="City"
              value={bookingData?.contact?.city}
              Icon={LocationCityIcon}
              editState={billingEditState}
              inputType="test"
              inputField="city"
              product="contact"
              onChangeValue={updateBookingValue}
            />
            <WidgetCard
              title="Province"
              value={`${bookingData?.contact?.province}`}
              Icon={PublicIcon}
              editState={billingEditState}
              inputType="test"
              inputField="province"
              product="contact"
              onChangeValue={updateBookingValue}
            />
            <WidgetCard
              title="Country"
              value={`${bookingData?.contact?.country}`}
              Icon={PublicIcon}
              editState={billingEditState}
              inputType="test"
              inputField="country"
              product="contact"
              onChangeValue={updateBookingValue}
            />
            <WidgetCard
              title="Postal Code"
              value={bookingData?.contact?.postalCode}
              Icon={FmdGoodIcon}
              editState={billingEditState}
              inputType="test"
              inputField="postalCode"
              product="contact"
              onChangeValue={updateBookingValue}
              componentWrapperStyles={{ border: 'none' }}
            />
          </Card>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <Box sx={{ py: 2 }}>
              <CardSubTitle label={`${CONTENT_LABELS.eventDetailTitle}`} sx={{ color: '#444444', fontSize: '1rem' }} />
            </Box>
            <WidgetCard title="Event Name" value={bookingData?.event?.name} Icon={EventIcon} />
            <WidgetCard
              title="Event Location"
              value={bookingData?.event?.location}
              Icon={StadiumIcon}
              isToolTip={!!(bookingData?.event?.venue_latitude !== '' && bookingData?.event?.venue_longitude !== '')}
              toolTipValue={
                bookingData?.event?.venue_latitude &&
                bookingData?.event?.venue_longitude && (
                  <>
                    <p>
                      <b>
                        <NumbersIcon sx={{ fontSize: '0.95rem', paddingTop: '5px' }} />
                        Venue ID:
                      </b>
                      {bookingData.event?.venue_id}
                    </p>
                    <p>
                      <b>
                        <MyLocationIcon sx={{ fontSize: '0.95rem', paddingTop: '5px' }} />
                        Lat | Long:
                      </b>
                      {bookingData.event?.venue_latitude} |{bookingData.event?.venue_longitude}
                    </p>
                  </>
                )
              }
            />
            <WidgetCard
              title="Event City"
              value={`${bookingData?.event?.location_city} ${
                bookingData?.event?.venue_country ? `, ${bookingData?.event?.venue_country}` : ''
              } `}
              Icon={LocationCityIcon}
            />
            <WidgetCard title="Event Date Time" value={bookingData?.event?.date} Icon={DateRangeIcon} />
            <WidgetCard
              title="Days Between"
              value={bookingData?.daysbetween}
              Icon={CalendarMonthIcon}
              componentWrapperStyles={{ border: 'none' }}
            />
          </Card>
        </Grid>

        {bookingData?.ticket && (
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                <CardSubTitle
                  label={`${CONTENT_LABELS.ticketDetailTitle}`}
                  sx={{ color: '#444444', fontSize: '1rem' }}
                />
                {ticketEditState ? (
                  <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('ticket')} />
                ) : (
                  <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('ticket')} />
                )}
              </Box>
              <WidgetCard
                title="Tickets Order"
                value={bookingData?.ticket?.bookingNumber}
                Icon={BookOnlineIcon}
                editState={ticketEditState}
                inputType="text"
                inputField="bookingNumber"
                product="ticket"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Quantity"
                value={bookingData?.ticket?.quantity}
                Icon={NumbersIcon}
                editState={ticketEditState}
                inputType="text"
                inputField="quantity"
                product="ticket"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Section"
                value={`${bookingData?.ticket?.section}`}
                Icon={EventSeatIcon}
                editState={ticketEditState}
                inputType="text"
                inputField="section"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Row"
                value={`${bookingData?.ticket?.row}`}
                Icon={EventSeatIcon}
                editState={ticketEditState}
                inputType="text"
                inputField="row"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Type"
                value={bookingData?.ticket?.type}
                Icon={EventSeatIcon}
                editState={ticketEditState}
                inputType="text"
                inputField="type"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Price Per Ticket"
                value={
                  ticketEditState
                    ? formatNumber(bookingData?.ticket?.price_per_ticket)
                    : currencyFormatter(bookingData?.ticket?.price_per_ticket)
                }
                Icon={AttachMoneyIcon}
                editState={ticketEditState}
                inputType="number"
                inputField="price_per_ticket"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Delivery Date"
                value={bookingData?.ticket?.delivery_date}
                Icon={CalendarMonthIcon}
                editState={ticketEditState}
                inputType="text"
                inputField="delivery_date"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Subtotal"
                value={
                  ticketEditState
                    ? formatNumber(bookingData?.ticket?.subTotal)
                    : currencyFormatter(bookingData?.ticket?.subTotal)
                }
                Icon={AttachMoneyIcon}
                editState={ticketEditState}
                inputType="number"
                inputField="subTotal"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title={`BookSeats.com Service Fee (${bookingData?.ticket?.chargeRate}%)`}
                value={
                  ticketEditState
                    ? formatNumber(bookingData?.ticket?.serviceChargeBS)
                    : currencyFormatter(bookingData?.ticket?.serviceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={ticketEditState}
                inputType="number"
                inputField="serviceChargeBS"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="BookSeats.com HST(13%)"
                value={
                  ticketEditState
                    ? formatNumber(bookingData?.ticket?.hstServiceChargeBS)
                    : currencyFormatter(bookingData?.ticket?.hstServiceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={ticketEditState}
                inputType="number"
                inputField="hstServiceChargeBS"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Taxes and Fees"
                value={
                  ticketEditState
                    ? formatNumber(bookingData?.ticket?.taxes)
                    : currencyFormatter(bookingData?.ticket?.taxes)
                }
                Icon={AttachMoneyIcon}
                editState={ticketEditState}
                inputType="number"
                inputField="taxes"
                product="ticket"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Total"
                value={
                  ticketEditState
                    ? formatNumber(bookingData?.ticket?.total)
                    : currencyFormatter(bookingData?.ticket?.total)
                }
                Icon={AttachMoneyIcon}
                componentWrapperStyles={{ border: 'none' }}
                editState={ticketEditState}
                inputType="number"
                inputField="total"
                product="ticket"
                onChangeValue={updateBookingValue}
              />
            </Card>
          </Grid>
        )}

        {bookingData?.hotel && (
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                <CardSubTitle
                  label={`${CONTENT_LABELS.hotelDetailTitle}`}
                  sx={{ color: '#444444', fontSize: '1rem' }}
                />
                {hotelEditState ? (
                  <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('hotel')} />
                ) : (
                  <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('hotel')} />
                )}
              </Box>
              <WidgetCard
                title="Hotel Itinerary ID"
                value={bookingData?.hotel?.bookingNumber}
                Icon={BookOnlineIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="bookingNumber"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Hotel Trip Number"
                value={bookingData?.hotel?.tripNumber}
                Icon={BookOnlineIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="tripNumber"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Hotel Name"
                value={bookingData?.hotel?.name}
                Icon={HotelIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="name"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Address"
                value={bookingData?.hotel?.address}
                Icon={LocationCityIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="address"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Check In Date"
                value={bookingData?.hotel?.checkIn}
                Icon={EventIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="checkIn"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Check out Date"
                value={bookingData?.hotel?.checkOut}
                Icon={EventIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="checkOut"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Room Type"
                value={bookingData?.hotel?.roomCategory}
                Icon={KingBedIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="roomCategory"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="No of Nights"
                value={bookingData?.hotel?.nights}
                Icon={NumbersIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="nights"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="No Room"
                value={bookingData?.hotel?.num_Rooms}
                Icon={NumbersIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="num_Rooms"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Adults"
                value={bookingData?.hotel?.adults}
                Icon={NumbersIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="adults"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Children"
                value={bookingData?.hotel?.children}
                Icon={NumbersIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="children"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Star Rating"
                value={bookingData?.hotel?.star_rating}
                Icon={StarIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="star_rating"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Review Rating"
                value={bookingData?.hotel?.review_rating}
                Icon={ReviewsIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="review_rating"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Amenities"
                value={bookingData?.hotel?.amenities}
                Icon={RoomPreferencesIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="amenities"
                product="hotel"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Cancellation"
                value={bookingData?.hotel?.cancellation}
                Icon={HighLightOffIcon}
                editState={hotelEditState}
                inputType="text"
                inputField="cancellation"
                product="hotel"
                onChangeValue={updateBookingValue}
              />
              {bookingData?.hotel?.commission && bookingData?.hotel?.supplier === 'Expedia' ? (
                <WidgetCard
                  title="Commission"
                  value={
                    hotelEditState
                      ? formatNumber(bookingData?.hotel?.commission)
                      : currencyFormatter(bookingData?.hotel?.commission)
                  }
                  Icon={AttachMoneyIcon}
                  editState={hotelEditState}
                  inputType="number"
                  inputField="Commission"
                  product="hotel"
                  onChangeValue={updateBookingValue}
                />
              ) : null}
              <WidgetCard
                title="Subtotal"
                value={
                  hotelEditState
                    ? formatNumber(bookingData?.hotel?.subTotal)
                    : currencyFormatter(bookingData?.hotel?.subTotal)
                }
                Icon={AttachMoneyIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="subTotal"
                product="hotel"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Hotel Taxes and Fees"
                value={
                  hotelEditState
                    ? formatNumber(bookingData?.hotel?.taxes)
                    : currencyFormatter(bookingData?.hotel?.taxes)
                }
                Icon={AttachMoneyIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="taxes"
                product="hotel"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title={`BookSeats.com Service Fee (${bookingData?.hotel?.chargeRate}%)`}
                value={
                  hotelEditState
                    ? formatNumber(bookingData?.hotel?.serviceChargeBS)
                    : currencyFormatter(bookingData?.hotel?.serviceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="serviceChargeBS"
                product="hotel"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="BookSeats.com HST(13%)"
                value={
                  hotelEditState
                    ? formatNumber(bookingData?.hotel?.hstServiceChargeBS)
                    : currencyFormatter(bookingData?.hotel?.hstServiceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="hstServiceChargeBS"
                product="hotel"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Total Taxes & Fees"
                value={
                  hotelEditState
                    ? formatNumber(bookingData?.hotel?.totalTaxesFees)
                    : currencyFormatter(bookingData?.hotel?.totalTaxesFees)
                }
                Icon={AttachMoneyIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="totalTaxesFees"
                product="hotel"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Total"
                value={
                  hotelEditState
                    ? formatNumber(bookingData?.hotel?.total)
                    : currencyFormatter(bookingData?.hotel?.total)
                }
                Icon={AttachMoneyIcon}
                editState={hotelEditState}
                inputType="number"
                inputField="total"
                product="hotel"
                onChangeValue={updateBookingValue}
                componentWrapperStyles={{ border: 'none' }}
              />
            </Card>
          </Grid>
        )}

        {bookingData?.flight && (
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                <CardSubTitle
                  label={`${CONTENT_LABELS.flightDetailTitle}`}
                  sx={{ color: '#444444', fontSize: '1rem' }}
                />
                {flightEditState ? (
                  <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('flight')} />
                ) : (
                  <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('flight')} />
                )}
              </Box>
              <WidgetCard
                title="Airline Locator"
                value={bookingData?.flight?.bookingNumber}
                Icon={AirplaneTicketIcon}
                editState={flightEditState}
                inputType="text"
                inputField="bookingNumber"
                product="flight"
                onChangeValue={updateBookingValue}
              />

              {bookingData?.flight?.passengers.length > 0 &&
                bookingData?.flight?.passengers.map((passenger, index) => (
                  <WidgetCard
                    title={`Passenger # ${index + 1}`}
                    value={
                      flightEditState
                        ? `${passenger.name}_${passenger.birthDate}`
                        : `${passenger.name} (${passenger.birthDate})`
                    }
                    Icon={LuggageIcon}
                    editState={flightEditState}
                    inputType="text"
                    inputField={index.toString()}
                    product="flightPassenger"
                    onChangeValue={updateBookingValue}
                  />
                ))}

              <FlightWidget
                title="Departure"
                Icon={FlightTakeoffIcon}
                flightInfo={{
                  flightNumber: bookingData?.flight?.departure?.flightNumber,
                  flightClass: bookingData?.flight?.departure?.flightClass,
                  stops: bookingData?.flight?.departure?.stops,
                  class_type: bookingData?.flight?.departure?.class_type,
                  farebasiscode: bookingData?.flight?.departure?.farebasiscode,
                  fromConnectionCity1: bookingData?.flight?.departure?.connectionCity1,
                  fromConnectionCity2: bookingData?.flight?.departure?.connectionCity2,
                  departureAriport: bookingData?.flight?.departure?.departureAriport,
                  arrivalAirport: bookingData?.flight?.departure?.arrivalAirport,
                  departDate: bookingData?.flight?.departure?.departDate,
                  departTime: bookingData?.flight?.departure?.departTime,
                  arrivalDate: bookingData?.flight?.departure?.arrivalDate,
                  arrivalTime: bookingData?.flight?.departure?.arrivalTime,
                  departureLocation: bookingData?.flight?.departure?.departureLocation,
                  arrivalLocation: bookingData?.flight?.departure?.arrivalLocation,
                  operatedBy: bookingData?.flight?.departure?.operatedBy,
                  seats: bookingData?.flight?.departure?.seats,
                  travelTime: bookingData?.flight?.departure?.travelTime,
                }}
                editState={flightEditState}
                product="departureFlight"
                onChangeValue={updateBookingValue}
              />

              {bookingData?.flight?.return && (
                <FlightWidget
                  title="Return"
                  Icon={FlightTakeoffIcon}
                  flightInfo={{
                    flightNumber: bookingData?.flight?.return?.flightNumber,
                    flightClass: bookingData?.flight?.return?.flightClass,
                    stops: bookingData?.flight?.return?.stops,
                    class_type: bookingData?.flight?.return?.class_type,
                    farebasiscode: bookingData?.flight?.return?.farebasiscode,
                    fromConnectionCity1: bookingData?.flight?.return?.connectionCity1,
                    fromConnectionCity2: bookingData?.flight?.return?.connectionCity2,
                    departureAriport: bookingData?.flight?.return?.departureAriport,
                    arrivalAirport: bookingData?.flight?.return?.arrivalAirport,
                    departDate: bookingData?.flight?.return?.departDate,
                    departTime: bookingData?.flight?.return?.departTime,
                    arrivalDate: bookingData?.flight?.return?.arrivalDate,
                    arrivalTime: bookingData?.flight?.return?.arrivalTime,
                    departureLocation: bookingData?.flight?.return?.departureLocation,
                    arrivalLocation: bookingData?.flight?.return?.arrivalLocation,
                    operatedBy: bookingData?.flight?.return?.operatedBy,
                    seats: bookingData?.flight?.return?.seats,
                    travelTime: bookingData?.flight?.return?.travelTime,
                  }}
                  editState={flightEditState}
                  product="returnFlight"
                  onChangeValue={updateBookingValue}
                />
              )}

              <WidgetCard
                title="Subtotal"
                value={
                  flightEditState
                    ? formatNumber(bookingData?.flight?.subTotal)
                    : currencyFormatter(bookingData?.flight?.subTotal)
                }
                Icon={AttachMoneyIcon}
                editState={flightEditState}
                inputType="text"
                inputField="subTotal"
                product="flight"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Flight Taxes and Fees"
                value={
                  flightEditState
                    ? formatNumber(bookingData?.flight?.taxesFees)
                    : currencyFormatter(bookingData?.flight?.taxesFees)
                }
                Icon={AttachMoneyIcon}
                editState={flightEditState}
                inputType="text"
                inputField="taxesFees"
                product="flight"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="BookSeats.com Service Fee (8%)"
                value={
                  flightEditState
                    ? formatNumber(bookingData?.flight?.serviceChargeBS)
                    : currencyFormatter(bookingData?.flight?.serviceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={flightEditState}
                inputType="number"
                inputField="serviceChargeBS"
                product="flight"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="BookSeats.com HST(13%)"
                value={
                  flightEditState
                    ? formatNumber(bookingData?.flight?.hstServiceChargeBS)
                    : currencyFormatter(bookingData?.flight?.hstServiceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={flightEditState}
                inputType="number"
                inputField="hstServiceChargeBS"
                product="flight"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Total Taxes & Fees"
                value={
                  flightEditState
                    ? formatNumber(bookingData?.flight?.taxes)
                    : currencyFormatter(bookingData?.flight?.taxes)
                }
                Icon={AttachMoneyIcon}
                editState={flightEditState}
                inputType="number"
                inputField="taxes"
                product="flight"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Total"
                value={
                  flightEditState
                    ? formatNumber(bookingData?.flight?.total)
                    : currencyFormatter(bookingData?.flight?.total)
                }
                Icon={AttachMoneyIcon}
                editState={flightEditState}
                inputType="number"
                inputField="total"
                product="flight"
                onChangeValue={updateBookingValue}
                componentWrapperStyles={{ border: 'none' }}
              />
            </Card>
          </Grid>
        )}

        {bookingData?.addOns && (
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                <CardSubTitle label={`${CONTENT_LABELS.addOnsTitle}`} sx={{ color: '#444444', fontSize: '1rem' }} />
                {addOnsEditState ? (
                  <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('addOns')} />
                ) : (
                  <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('addOns')} />
                )}
              </Box>
              <WidgetCard
                title="Event Extra Title"
                value={bookingData?.addOns?.itemName}
                Icon={TitleIcon}
                editState={addOnsEditState}
                inputType="text"
                inputField="itemName"
                product="addOns"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Event Extra Details"
                value={bookingData?.addOns?.itemDescriptionShort}
                Icon={SubtitlesIcon}
                editState={addOnsEditState}
                inputType="text"
                inputField="itemDescriptionShort"
                product="addOns"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="quantity"
                value={bookingData?.addOns?.quantity}
                Icon={NumbersIcon}
                editState={addOnsEditState}
                inputType="text"
                inputField="quantity"
                product="addOns"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Subtotal"
                value={
                  addOnsEditState
                    ? formatNumber(bookingData?.addOns?.subTotal)
                    : currencyFormatter(bookingData?.addOns?.subTotal)
                }
                Icon={AttachMoneyIcon}
                editState={addOnsEditState}
                inputType="number"
                inputField="subTotal"
                product="addOns"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Addons Taxes and Fees"
                value={addOnsEditState ? formatNumber(bookingData?.addOns?.taxes) : currencyFormatter(0)}
                Icon={AttachMoneyIcon}
                editState={addOnsEditState}
                inputType="number"
                inputField="taxes"
                product="addOns"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="BookSeats.com Service Fee (10%)"
                value={
                  addOnsEditState
                    ? formatNumber(bookingData?.addOns?.serviceChargeBS)
                    : currencyFormatter(bookingData?.addOns?.serviceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={addOnsEditState}
                inputType="number"
                inputField="serviceChargeBS"
                product="addOns"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="BookSeats.com HST(13%)"
                value={
                  addOnsEditState
                    ? formatNumber(bookingData?.addOns?.hstServiceChargeBS)
                    : currencyFormatter(bookingData?.addOns?.hstServiceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={addOnsEditState}
                inputType="number"
                inputField="hstServiceChargeBS"
                product="addOns"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Total Taxes & Fees"
                value={
                  addOnsEditState
                    ? formatNumber(bookingData?.addOns?.totalTaxesFees)
                    : currencyFormatter(bookingData?.addOns?.totalTaxesFees)
                }
                Icon={AttachMoneyIcon}
                editState={addOnsEditState}
                inputType="number"
                inputField="totalTaxesFees"
                product="addOns"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Total"
                value={
                  addOnsEditState
                    ? formatNumber(bookingData?.addOns?.total)
                    : currencyFormatter(bookingData?.addOns?.total)
                }
                Icon={AttachMoneyIcon}
                componentWrapperStyles={{ border: 'none' }}
                editState={addOnsEditState}
                inputType="number"
                inputField="total"
                product="addOns"
                onChangeValue={updateBookingValue}
              />
            </Card>
          </Grid>
        )}

        {bookingData?.insurance && (
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                <CardSubTitle
                  label={`${CONTENT_LABELS.insuranceDetailTitle}`}
                  sx={{ color: '#444444', fontSize: '1rem' }}
                />
                {insuranceEditState ? (
                  <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('insurance')} />
                ) : (
                  <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('insurance')} />
                )}
              </Box>
              <WidgetCard
                title="Sales ID"
                value={bookingData?.insurance?.bookingNumber}
                Icon={Gird3x3Icon}
                editState={insuranceEditState}
                inputType="text"
                inputField="bookingNumber"
                product="insurance"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Quote Id"
                value={bookingData?.insurance?.quoteId}
                Icon={Gird3x3Icon}
                editState={insuranceEditState}
                inputType="text"
                inputField="quoteId"
                product="insurance"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Refundable Link"
                value={
                  bookingData?.insurance?.refundableLink && bookingData?.insurance?.refundableLink !== 'PENDING' ? (
                    <a href={bookingData?.insurance?.refundableLink} target="blank">
                      Apply for Refund
                    </a>
                  ) : (
                    'Pending'
                  )
                }
                Icon={LinkIcon}
                editState={insuranceEditState}
                inputType="text"
                inputField="refundableLink"
                product="insurance"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Subtotal"
                value={
                  insuranceEditState
                    ? formatNumber(bookingData?.insurance?.subTotal)
                    : currencyFormatter(bookingData?.insurance?.subTotal)
                }
                Icon={AttachMoneyIcon}
                editState={insuranceEditState}
                inputType="number"
                inputField="subTotal"
                product="insurance"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Insurance Taxes and Fees"
                value={insuranceEditState ? formatNumber(bookingData?.insurance?.taxes) : currencyFormatter(0)}
                Icon={AttachMoneyIcon}
                editState={insuranceEditState}
                inputType="number"
                inputField="taxes"
                product="insurance"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="BookSeats.com Service Fee (40%)"
                value={
                  insuranceEditState
                    ? formatNumber(bookingData?.insurance?.insuranceBSServiceFees)
                    : currencyFormatter(bookingData?.insurance?.insuranceBSServiceFees)
                }
                Icon={AttachMoneyIcon}
                editState={insuranceEditState}
                inputType="number"
                inputField="insuranceBSServiceFees"
                product="insurance"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="BookSeats.com HST(13%)"
                value={
                  insuranceEditState
                    ? formatNumber(bookingData?.insurance?.insuranceHSTTax)
                    : currencyFormatter(bookingData?.insurance?.insuranceHSTTax)
                }
                Icon={AttachMoneyIcon}
                editState={insuranceEditState}
                inputType="number"
                inputField="insuranceHSTTax"
                product="insurance"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Total Taxes & Fees"
                value={
                  insuranceEditState
                    ? formatNumber(bookingData?.insurance?.totalTaxesFees)
                    : currencyFormatter(bookingData?.insurance?.totalTaxesFees)
                }
                Icon={AttachMoneyIcon}
                editState={insuranceEditState}
                inputType="number"
                inputField="totalTaxesFees"
                product="insurance"
                onChangeValue={updateBookingValue}
              />
              <WidgetCard
                title="Total"
                value={
                  insuranceEditState
                    ? formatNumber(bookingData?.insurance?.total)
                    : currencyFormatter(bookingData?.insurance?.total)
                }
                Icon={AttachMoneyIcon}
                componentWrapperStyles={{ border: 'none' }}
                editState={insuranceEditState}
                inputType="number"
                inputField="total"
                product="insurance"
                onChangeValue={updateBookingValue}
              />
            </Card>
          </Grid>
        )}

        {bookingData?.payment && (
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                <CardSubTitle
                  label={`${CONTENT_LABELS.priceSummaryTitle}`}
                  sx={{ color: '#444444', fontSize: '1rem' }}
                />
                {paymentEditState ? (
                  <SaveIcon sx={{ cursor: 'pointer' }} onClick={() => save('payment')} />
                ) : (
                  <EditIcon sx={{ cursor: 'pointer' }} onClick={() => edit('payment')} />
                )}
              </Box>

              <WidgetCard
                title="Subtotal"
                value={
                  paymentEditState
                    ? formatNumber(bookingData?.payment?.subTotal)
                    : currencyFormatter(bookingData?.payment?.subTotal)
                }
                Icon={AttachMoneyIcon}
                editState={paymentEditState}
                inputType="number"
                inputField="subTotal"
                product="payment"
                onChangeValue={updateBookingValue}
              />

              {!!bookingData?.insurance?.subTotal && bookingData?.insurance?.subTotal >= 0 && (
                <WidgetCard
                  title="Refundable Booking"
                  value={
                    paymentEditState
                      ? formatNumber(bookingData?.insurance?.subTotal)
                      : currencyFormatter(bookingData?.insurance?.subTotal)
                  }
                  Icon={AttachMoneyIcon}
                  editState={paymentEditState}
                  inputType="number"
                  inputField="subTotal"
                  product="insurance"
                  onChangeValue={updateBookingValue}
                />
              )}

              <WidgetCard
                title="Taxes and Fees"
                value={
                  paymentEditState
                    ? formatNumber(bookingData?.payment?.taxes)
                    : currencyFormatter(bookingData?.payment?.taxes)
                }
                Icon={AttachMoneyIcon}
                editState={paymentEditState}
                inputType="number"
                inputField="taxes"
                product="payment"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="Total"
                value={
                  paymentEditState
                    ? formatNumber(bookingData?.payment?.baseTotal)
                    : currencyFormatter(bookingData?.payment?.baseTotal)
                }
                Icon={AttachMoneyIcon}
                editState={paymentEditState}
                inputType="number"
                inputField="baseTotal"
                product="payment"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="BookSeats.com Service Fee"
                value={
                  paymentEditState
                    ? formatNumber(bookingData?.payment?.serviceChargeBS)
                    : currencyFormatter(bookingData?.payment?.serviceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={paymentEditState}
                inputType="number"
                inputField="serviceChargeBS"
                product="payment"
                onChangeValue={updateBookingValue}
              />

              <WidgetCard
                title="BookSeats.com HST(13%)"
                value={
                  paymentEditState
                    ? formatNumber(bookingData?.payment?.hstServiceChargeBS)
                    : currencyFormatter(bookingData?.payment?.hstServiceChargeBS)
                }
                Icon={AttachMoneyIcon}
                editState={paymentEditState}
                inputType="number"
                inputField="hstServiceChargeBS"
                product="payment"
                onChangeValue={updateBookingValue}
              />

              {bookingData?.payment && bookingData?.payment?.promoCode && bookingData?.payment.savings > 0 && (
                <WidgetCard
                  title={`Savings (${bookingData?.payment?.promoCode})`}
                  value={
                    paymentEditState
                      ? formatNumber(bookingData?.payment?.savings)
                      : currencyFormatter(bookingData?.payment?.savings)
                  }
                  Icon={AttachMoneyIcon}
                  editState={paymentEditState}
                  inputType="number"
                  inputField="savings"
                  product="payment"
                  onChangeValue={updateBookingValue}
                />
              )}

              <WidgetCard
                title={`BookSeats.com Total ${bookingData?.payment?.currency}`}
                value={
                  paymentEditState
                    ? formatNumber(bookingData?.payment?.total)
                    : currencyFormatter(bookingData?.payment?.total)
                }
                Icon={AttachMoneyIcon}
                componentWrapperStyles={{ border: 'none' }}
                editState={paymentEditState}
                inputType="number"
                inputField="total"
                product="payment"
                onChangeValue={updateBookingValue}
              />
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

const CONTENT_LABELS = {
  pageTitle: 'Orders Summary',
  orderDetailTitle: 'Orders Details',
  customerDetailTitle: 'Customer Details',
  billingDetailTitle: 'Billing Details',
  eventDetailTitle: 'Event Information',
  ticketDetailTitle: 'Event Ticket Information',
  hotelDetailTitle: 'Hotel Information',
  insuranceDetailTitle: 'Insurance Information',
  addOnsTitle: 'Event Extras',
  flightDetailTitle: 'Flight Information',
  priceSummaryTitle: 'Price Summary',
}
