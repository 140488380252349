import { object, string } from 'yup'

export const FORM_FIELDS = {
  otp: 'otp',
}

export const formFieldsConfig = {
  initialValues: {
    otp: null,
  },
  otp: {
    placeholder: 'Authentication Code',
    name: FORM_FIELDS.otp,
    type: 'number',
  },
}

export const validationSchema = object().shape({
  otp: string()
    .required('Please enter authentication code')
    .matches(/^\d+$/, 'Authentication Code must be only digits')
    .min(6, 'Authentication Code Should consist 6 Digit')
    .max(6, 'Authentication Code Should consist 6 Digit'),
})
