import { Box, Typography, SxProps } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'
import { fontWeight } from 'styles/theme'

interface WidgetStateProps {
  title?: string
  value?: string | number
  Icon?: SvgIconComponent
  componentWrapperStyles?: SxProps
}

export default function WidgetCard({
  title,
  value,
  Icon,
  componentWrapperStyles,
}: WidgetStateProps) {
  return (
    <Box
      sx={
        {
          ...widgetStyles.containerStyle,
          ...componentWrapperStyles,
        } as SxProps
      }
    >
      <Typography sx={widgetStyles.typographyStyle}>
        {Icon && <Icon sx={widgetStyles.iconStyle} />}
        {title}
      </Typography>
      <Typography sx={{ ...widgetStyles.valueStyle }}>{value}</Typography>
    </Box>
  )
}

interface WidgetStylesType {
  containerStyle: SxProps
  typographyStyle: SxProps
  iconStyle: SxProps
  valueStyle: SxProps
}

const widgetStyles: WidgetStylesType = {
  containerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    py: '18px',
    borderBottom: '1px solid #dbd3d3',
  },
  iconStyle: { fontSize: '0.85rem', marginRight: '5px' },
  valueStyle: { fontWeight: fontWeight.bold, fontSize: '0.85rem' },
  typographyStyle: {
    fontSize: '0.85rem',
  },
}
