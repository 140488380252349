import { Box, SxProps, Typography } from '@mui/material'
import { DOMAIN } from 'helpers/Constants'
import { useEffect, useRef, useState } from 'react'
import { COLORS } from 'styles'
import { fontWeight } from 'styles/theme'
import { currencyFormatter } from 'helpers/Methods/utils'
import { Chart, ChartRef, WidgetCard } from 'components/UI'

interface TopPieChartProps {
  titleText: string
  data: {
    total_revenue: number
    domain: string
  }[]
  chartHeight: string
}

export default function TopPie({
  titleText,
  data,
  chartHeight,
}: TopPieChartProps) {
  const [chartData, setChartData] = useState({ ...options })
  const chartComponent = useRef<ChartRef>()

  const toggleData = (dataItem, index: number) => {
    const newChartData = { ...chartData }
    newChartData.series[0].data[index].visible = !dataItem.visible
    setChartData(newChartData)
  }

  useEffect(() => {
    if (data) {
      // const pieColor = [COLORS.PRIMARY, COLORS.SECONDARY]
      setChartData({
        ...options,
        chart: {
          ...options.chart,
          height: chartHeight ?? '300px',
        },
        series: [
          {
            name: 'Domains',
            colorByPoint: false,
            innerSize: '70%',
            type: 'pie',
            data: data?.map((value) => ({
              name: DOMAIN[value.domain].name,
              y: Math.round(value.total_revenue),
              visible: true,
              color: DOMAIN[value.domain].color,
              //  pieColor[index],
            })),
          },
        ],
      })
      chartComponent?.current?.chart.reflow()
    }
  }, [chartHeight, data, titleText])

  return (
    <WidgetCard title={titleText}>
      {/* Title */}
      <Box sx={topPieStyles.titleContainer}>
        <Typography sx={topPieStyles.titleText}>
          {currencyFormatter(
            data?.reduce(
              (accumulator, domain) => accumulator + Math.round(domain.total_revenue),
              0,
            ),
            false,
          )}
          {/* <Typography component="span" sx={topPieStyles.titleTextHighlight}>
            $
          </Typography> */}
        </Typography>
      </Box>
      {/* <Box>
        <CardSubTitle label={titleText} /> */}
      {/* <Typography sx={topPieStyles.subTitleText}>{}</Typography> */}
      {/* </Box> */}

      {/* Chart  */}
      <Box sx={topPieStyles.chartWrapper}>
        <Box sx={topPieStyles.chartSizeWrapper}>
          <Chart ref={chartComponent} options={{ ...chartData }} />
        </Box>
      </Box>

      {/* Legends */}
      <Box sx={topPieStyles.legendsWrapper}>
        {chartData.series[0].data.map((seriesItem, index) => (
          <Box
            sx={
              [
                topPieStyles.legendItem,
                !seriesItem.visible && topPieStyles.legendItemHidden,
              ] as SxProps
            }
            key={seriesItem.name}
            onClick={() => toggleData(seriesItem, index)}
          >
            <Box
              sx={
                [
                  topPieStyles.legendIcon,
                  { backgroundColor: seriesItem.color },
                ] as SxProps
              }
            />
            <Box sx={topPieStyles.legendTextWrapper}>
              <Typography sx={topPieStyles.legendText}>
                {seriesItem.name}
              </Typography>
            </Box>
            <Box sx={topPieStyles.legendValueWrapper}>
              <Typography sx={topPieStyles.legendValue}>
                {currencyFormatter(seriesItem.y, false)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </WidgetCard>
  )
}

interface TopPieStylesType {
  titleContainer: SxProps
  titleText: SxProps
  titleTextHighlight: SxProps
  chartWrapper: SxProps
  chartSizeWrapper: SxProps
  legendsWrapper: SxProps
  legendItem: SxProps
  legendItemHidden: SxProps
  legendIcon: SxProps
  legendTextWrapper: SxProps
  legendText: SxProps
  legendValueWrapper: SxProps
  legendValue: SxProps
}

const SIZES = {
  LEGEND_ICON: 10,
  LEGEND_VALUE: 150,
}

export const topPieStyles: TopPieStylesType = {
  titleContainer: {},
  titleText: { fontSize: 32, fontWeight: fontWeight.bold, lineHeight: 1.1 },
  titleTextHighlight: { color: COLORS.FADED_TEXT },
  chartWrapper: {
    display: 'flex',
    justifyContent: 'center',
    my: 1.5,
  },
  chartSizeWrapper: {
    width: 250,
  },
  legendsWrapper: {
    maxWidth: 250,
    margin: '0 auto',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendItemHidden: {
    opacity: 0.3,
  },
  legendIcon: {
    height: SIZES.LEGEND_ICON,
    width: SIZES.LEGEND_ICON,
    borderRadius: '50%',
  },
  legendTextWrapper: {
    width: `calc(100% - ${SIZES.LEGEND_ICON}px - ${SIZES.LEGEND_VALUE}px)`,
    px: 1,
  },
  legendText: {
    textTransform: 'capitalize',
    color: COLORS.FADED_TEXT,
    fontSize: 14,
  },
  legendValueWrapper: {
    width: SIZES.LEGEND_VALUE,
    textAlign: 'right',
  },
  legendValue: {
    fontSize: 14,
    fontWeight: fontWeight.bold,
  },
}

const options = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height: '100%',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: 'Domains',
      colorByPoint: false,
      innerSize: '70%',
      type: 'pie',
      data: [
        {
          name: 'BookSeats',
          y: 0,
          color: COLORS.SECONDARY,
          visible: true,
        },
        {
          name: 'GolfPass',
          y: 0,
          color: COLORS.PRIMARY,
          visible: true,
        },
      ],
    },
  ],
}
