import { createAction } from '@reduxjs/toolkit'
import { LookUpInfo } from './app.state'
import { UPDATE_APP_SIDEBAR_SHOW, UPDATE_LOOKUP_DATA } from './app.types'

const appActions = {
  updateAppSidebarShow: createAction<boolean>(UPDATE_APP_SIDEBAR_SHOW),
  setLookupData: createAction<LookUpInfo>(UPDATE_LOOKUP_DATA),
}

export default appActions
