import sidebarLinks from 'components/Navigation/Sidebar/SidebarLinks'
import { AccessTypes, Modules } from 'helpers/Constants'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import authActions from 'redux/Auth/auth.action'
import { selectPermissions, selectPermittedModules, selectUser } from 'redux/Auth/auth.selectors'
import { useAppSelector } from 'redux/hooks'
import { crmUsersService } from 'services'

export const useGetPermitted = () => {
  const permittedModules = useSelector(selectPermittedModules)
  const permittedLinks = useMemo(() => {
    const modulesMap = new Map<Modules, boolean>()
    permittedModules.forEach((module) => {
      modulesMap.set(module.module.name, true)
    })
    return sidebarLinks.filter((link) => modulesMap.get(link.module))
  }, [permittedModules])

  return {
    permittedModules,
    permittedLinks,
  }
}

export const useFetchPermission = () => {
  const user = useAppSelector(selectUser)
  const dispatch = useDispatch()

  const getPermissions = useCallback(async () => {
    if (!user?.id) return

    const { response } = await crmUsersService.getUser(user.id)
    dispatch(authActions.updateUser(response.data))
  }, [dispatch, user?.id])

  useEffect(() => {
    getPermissions()
  }, [getPermissions])
}

export const usePermission = () => {
  const permissions = useSelector(selectPermissions)

  const getPermission = useCallback(
    (module: Modules) => {
      return permissions.find((permission) => permission.module.name === module)
    },
    [permissions],
  )

  const hasPermission = useCallback(
    (module: Modules, accessType: AccessTypes) => {
      const permission = getPermission(module)
      return Boolean(permission?.[accessType])
    },
    [getPermission],
  )

  return {
    permissions,
    getPermission,
    hasPermission,
  }
}
