import { RightSidebarProps } from 'components/Navigation/Sidebar'
import { LABELS, validationSchema } from './Components'
import { useFormik } from 'formik'
import { useEditCrmData } from './EditCrmUser.controller'
import { useCallback, useState } from 'react'
import { crmUsersService } from 'services'
import useToast from 'components/UI/Toast'
import AddUserDrawer from './AddUserDrawer'

interface AddCrmUserProps extends Omit<RightSidebarProps, 'headerText'> {
  onAddSuccess: () => void
}

export default function AddCrmUser({ onAddSuccess, ...props }: AddCrmUserProps) {
  const { domains, permissions, onPermissionChange, onDomainChange } = useEditCrmData()
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: { name: '', email: '' },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {},
    validateOnMount: true,
  })

  const onSave = useCallback(async () => {
    formik.validateForm()
    if (!formik.isValid) return

    setLoading(true)
    const { values } = formik

    try {
      const userDomains = domains.filter((domain) => domain.checked).map((domain) => domain.id)
      const userPermissions = permissions.filter(
        (permission) =>
          permission.read ||
          permission.write ||
          permission.delete ||
          permission.canViewUserData ||
          permission.canViewPaymentData,
      )
      await crmUsersService.addUser({
        ...values,
        userDomains,
        permissions: userPermissions,
      })
      onAddSuccess()
      props.onClose()
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      setLoading(false)
    }
  }, [domains, formik, onAddSuccess, permissions, props, toast])

  return (
    <AddUserDrawer
      headerText={LABELS.addUser}
      loading={loading}
      onSave={onSave}
      domains={domains}
      onDomainChange={onDomainChange}
      permissions={permissions}
      onPermissionChange={onPermissionChange}
      formik={formik}
      {...props}
    />
  )
}
