import { Box, BoxProps, SxProps, Typography } from '@mui/material'
import {
  Chart,
  ChartProps,
  ChartOptionsProps,
  ChartRef,
  chartBaseOptions,
} from 'components/UI'
import { useRef, useState, useEffect } from 'react'
import { COLORS } from 'styles'
import { CHART_COLORS } from 'styles/colors'
import { fontWeight } from 'styles/theme'

interface PieChartProps extends ChartOptionsProps {
  /**
   * props applied to chart wrapper
   */
  chartWrapperProps?: BoxProps
}

export default function PieChart({
  chartWrapperProps,
  ...props
}: PieChartProps) {
  const [chartData, setChartData] = useState<ChartProps>(chartOptions(props))

  const chartComponent = useRef<ChartRef>()

  const toggleData = (dataItem, index: number) => {
    const newChartData = { ...chartData }
    newChartData.series[0].data[index].visible = !dataItem.visible
    setChartData(newChartData)
  }

  useEffect(() => {
    setChartData(chartOptions(props))
    chartComponent?.current?.chart.reflow()
  }, [props, props.series, props.xAxisLabels])

  return (
    <>
      <Box {...chartWrapperProps}>
        <Chart ref={chartComponent} options={{ ...chartData }} />
      </Box>

      {/* Legends */}
      <Box sx={pieStyles.legendsWrapper}>
        {chartData.series[0]?.data.map((seriesItem, index) => (
          <Box
            sx={
              [
                pieStyles.legendItem,
                !seriesItem.visible && pieStyles.legendItemHidden,
              ] as SxProps
            }
            key={seriesItem.name}
            onClick={() => toggleData(seriesItem, index)}
          >
            <Box
              sx={
                [
                  pieStyles.legendIcon,
                  { backgroundColor: CHART_COLORS[index] },
                ] as SxProps
              }
            />
            <Box sx={pieStyles.legendTextWrapper}>
              <Typography sx={pieStyles.legendText}>
                {seriesItem.name}
              </Typography>
            </Box>
            <Box sx={pieStyles.legendValueWrapper}>
              <Typography sx={pieStyles.legendValue}>
                {props?.isRevenue ? '$' : ''}
                {seriesItem.y}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}

interface PieStylesType {
  legendsWrapper: SxProps
  legendItem: SxProps
  legendItemHidden: SxProps
  legendIcon: SxProps
  legendTextWrapper: SxProps
  legendText: SxProps
  legendValueWrapper: SxProps
  legendValue: SxProps
}

const SIZES = {
  LEGEND_ICON: 8,
}

export const pieStyles: PieStylesType = {
  legendsWrapper: {
    margin: '0 auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    px: 1,
  },
  legendItemHidden: {
    opacity: 0.3,
  },
  legendIcon: {
    height: SIZES.LEGEND_ICON,
    width: SIZES.LEGEND_ICON,
    borderRadius: '50%',
  },
  legendTextWrapper: {
    px: 1,
  },
  legendText: {
    textTransform: 'capitalize',
    color: COLORS.FADED_TEXT,
    fontSize: 12,
  },
  legendValueWrapper: {
    textAlign: 'right',
  },
  legendValue: {
    fontSize: 14,
    fontWeight: fontWeight.bold,
  },
}

const chartOptions = (options: ChartOptionsProps) => {
  const config = {
    ...options,
    series: [...options.series],
  }

  // below transform is needed as pie chart has different architecture than column chart
  if (config.series.length > 0)
    config.series = [
      {
        name: '',
        colorByPoint: true,
        innerSize: '70%',
        data: config.series[0].data.map((item, index) => ({
          y: Math.round(item),
          name: config.xAxisLabels[index],
          visible: true,
        })),
      },
    ]

  return {
    ...chartBaseOptions('pie', config, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: '100%',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          size: '75%',
          borderRadius: 5,
          dataLabels: {
            enabled: true,
            format: '{point.name} {point.percentage:.1f}%',
            distance: 5,
            connectorWidth: 0,
            padding: 0,
            connectorPadding: 0,
            filter: {
              property: 'percentage',
              operator: '>',
              value: 4,
            },
            style: {
              textOverflow: 'clip',
              fontWeight: 'normal',
              fontSize: 10,
            },
          },
        },
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>',
        shared: false,
      },
    }),
  }
}
