const response = {
  stats: {
    totalRevenue: '10,000 $',
    totalBookings: '125',
    totalProfit: '1652 $',
    profitPercentage: '17%',
    avgHotelStars: '4.12',
    avgReviewRating: '4.35',
    orderEventGap: '12 Days',
    quantityPerOrder: '3',
    averagePrice: '75 $',
    avgAge: '28',
    avgNights: '3',
    avgRooms: '2',
  },
  bookingTimeDiff: {
    xAxisLabels: ['0-7', '8-14', '15-30', '31-60', '60+'],
    series: [
      {
        name: 'Bookings',
        data: [46, 12, 25, 20, 35],
      },
    ],
  },
  revenuePerProduct: {
    xAxisLabels: ['Flight', 'Hotel', 'Ticket', 'Golf', 'Insurance'],
    series: [
      {
        name: 'Bookings',
        data: [460, 1200, 250, 750, 350],
      },
    ],
  },
  airlineRevenue: {
    xAxisLabels: [
      'Air Canada',
      'American Airlines',
      'United Airlines',
      'Delta Airlines',
      'West Jet',
      'Other',
    ],
    series: [
      {
        name: 'Airlines',
        data: [1653, 4621, 8000, 750, 3500],
      },
    ],
  },
  monthlyRevenue: {
    xAxisLabels: [
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
    ],
    series: [
      {
        name: '2023',
        data: [1361, 8648, 2943, 5000, 10_000, 1653, 4621, 8000, 750, 3500],
      },
      {
        name: '2022',
        data: [10_000, 2500, 3100, 4752, 4500, 1700, 5000, 7000, 1000, 2000],
      },
    ],
  },
  monthlySubscriptions: {
    xAxisLabels: [
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
    ],
    series: [
      {
        name: 'Users',
        data: [1361, 8648, 2943, 5000, 10_000, 1653, 4621, 8000, 750, 3500],
      },
    ],
  },
  eventsPerMonth: {
    xAxisLabels: [
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
    ],
    series: [
      {
        name: 'Users',
        data: [13, 8, 29, 5, 10, 16, 4, 8, 7, 35],
      },
    ],
  },
  passengerAge: {
    xAxisLabels: ['0-9', '20-40', '41-60', '61+'],
    series: [
      {
        name: 'Users',
        data: [13, 80, 29, 5],
      },
    ],
  },
  hotelRatings: {
    xAxisLabels: ['1', '2', '3', '4', '5'],
    series: [
      {
        name: 'Users',
        data: [13, 29, 80, 50, 2],
      },
    ],
  },
  hotelRoomTypes: {
    xAxisLabels: [
      'Standard',
      'Deluxe',
      'Premium',
      'Suite',
      'Penthouse',
      'Presidential suite',
    ],
    series: [
      {
        name: 'Users',
        data: [130, 179, 100, 25, 5, 1],
      },
    ],
  },
  topEvents: {
    series: [
      {
        keyword: 'Baltimore Orioles',
        count: 14,
        id: 1,
      },
      {
        keyword: 'Boston Red Sox',
        count: 9,
        id: 2,
      },
      {
        keyword: 'New York Yankees',
        count: 5,
        id: 3,
      },
      {
        keyword: 'Tampa Bay Rays',
        count: 1,
        id: 4,
      },
      {
        keyword: 'Toronto Blue Jays',
        count: 1,
        id: 5,
      },
    ],
  },
  topPerformers: {
    series: [
      {
        keyword: 'Aaron Judge',
        count: 91.7,
        id: 1,
      },
      {
        keyword: 'Paul Goldschmidt',
        count: 84.8,
        id: 2,
      },
      {
        keyword: 'Freddie Freeman',
        count: 78.3,
        id: 3,
      },
      {
        keyword: 'Yordan Alvarez',
        count: 74.9,
        id: 4,
      },
      {
        keyword: 'Manny Machado',
        count: 74.8,
        id: 5,
      },
    ],
  },
  topDestination: {
    series: [
      {
        keyword: 'AT&T Stadium',
        count: 91,
        id: 1,
      },
      {
        keyword: 'Santiago Bernabéu Stadium',
        count: 80,
        id: 2,
      },
      {
        keyword: 'Wrigley Field',
        count: 70,
        id: 3,
      },
      {
        keyword: 'Pebble Beach Golf Links',
        count: 63,
        id: 4,
      },
      {
        keyword: 'Churchill Downs',
        count: 53,
        id: 5,
      },
    ],
  },
  topDepartureCities: {
    series: [
      {
        keyword: 'Atlanta, Georgia',
        count: 91,
        id: 1,
      },
      {
        keyword: 'Dallas-Fort Worth, Texas',
        count: 80,
        id: 2,
      },
      {
        keyword: 'Denver, Colorado',
        count: 70,
        id: 3,
      },
      {
        keyword: 'Chicago, Illinois',
        count: 63,
        id: 4,
      },
      {
        keyword: 'Garhoud, Dubai',
        count: 53,
        id: 5,
      },
    ],
  },
  topArrivalCities: {
    series: [
      {
        keyword: 'Atlanta, Georgia',
        count: 91,
        id: 1,
      },
      {
        keyword: 'Dallas-Fort Worth, Texas',
        count: 80,
        id: 2,
      },
      {
        keyword: 'Denver, Colorado',
        count: 70,
        id: 3,
      },
      {
        keyword: 'Chicago, Illinois',
        count: 63,
        id: 4,
      },
      {
        keyword: 'Garhoud, Dubai',
        count: 53,
        id: 5,
      },
    ],
  },
  topVenues: {
    series: [
      {
        keyword: 'Michigan Stadium',
        count: 52,
        id: 1,
      },
      {
        keyword: 'The Yale Bowl',
        count: 35,
        id: 2,
      },
      {
        keyword: 'The Los Angeles Memorial Coliseum',
        count: 32,
        id: 3,
      },
      {
        keyword: 'Lamade Stadium',
        count: 12,
        id: 4,
      },
      {
        keyword: 'The Astrodome',
        count: 5,
        id: 5,
      },
    ],
  },
  topStates: {
    series: [
      {
        keyword: 'Utah',
        count: 52,
        id: 1,
      },
      {
        keyword: 'Nebraska',
        count: 35,
        id: 2,
      },
      {
        keyword: 'Florida',
        count: 32,
        id: 3,
      },
      {
        keyword: 'Wisconsin',
        count: 12,
        id: 4,
      },
      {
        keyword: 'Washington',
        count: 5,
        id: 5,
      },
    ],
  },
  ticketType: {
    xAxisLabels: ['Mobile', 'Instant', 'Hard', 'Other'],
    series: [
      {
        name: 'Type',
        data: [55, 36, 30, 20],
      },
    ],
  },
  passengerGender: {
    xAxisLabels: ['Male', 'Female', 'Other'],
    series: [
      {
        name: 'Gender',
        data: [55, 40, 5],
      },
    ],
  },
  classOfService: {
    xAxisLabels: ['Economy Class', 'Business Class', 'First Class'],
    series: [
      {
        name: 'Class',
        data: [55, 40, 15],
      },
    ],
  },
  hotelCancellation: {
    xAxisLabels: ['Yes', 'No'],
    series: [
      {
        name: 'Response',
        data: [15, 85],
      },
    ],
  },
  currency: {
    xAxisLabels: ['USD', 'CAD'],
    series: [
      {
        name: 'Currency',
        data: [55, 15],
      },
    ],
  },
}

export default response
