import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { customerAnalyticsResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'

const getAnalyticsData = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi(customerAnalyticsResponse) : apiHandler.getRequest('customerAnalytics', data)

const customerAnalytics = {
  getAnalyticsData,
}

export default customerAnalytics
