import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './auth.state'
import authActions from './auth.action'

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },

  extraReducers: (builder) => {
    builder
      .addCase(authActions.updateAuthState, (state, action) => {
        localStorage.setItem('token', action.payload.token)
        localStorage.setItem('user', JSON.stringify(action.payload.user))

        state.token = action.payload.token
        state.user = action.payload.user
      })
      .addCase(authActions.updateUser, (state, action) => {
        localStorage.setItem('user', JSON.stringify(action.payload))
        state.user = action.payload
      })
      .addCase(authActions.userLogout, (state) => {
        localStorage.clear()
        state.token = ''
        state.user = null
      })
  },
})

export default authSlice.reducer
