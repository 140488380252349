import { FormHelperText, Input, InputBaseProps, SxProps } from '@mui/material'
import { useMemo } from 'react'
import { COLORS } from 'styles'

export interface TextFieldProps extends InputBaseProps {
  /**
   * Error to show
   */
  helperText?: string

  /**
   * enable outline
   * @default false
   */

  outline?: boolean
}

function TextField({ sx, helperText, error, outline = false, ...props }: TextFieldProps) {
  const outLineStyle = useMemo(() => {
    const styles: SxProps = {
      borderColor: 'transparent',
    }

    if (outline) {
      styles.borderColor = COLORS.CADET_BLUE
      styles['&.Mui-focused'] = { borderColor: COLORS.PRIMARY_DARK }
    }

    if (error) styles.borderColor = 'error.main'

    return styles
  }, [error, outline])
  return (
    <>
      <Input
        sx={
          [
            {
              backgroundColor: COLORS.BRIGHT_GRAY,
              paddingX: 2,
              paddingY: 0.75,
              border: '2px solid',
              borderRadius: '5px',
            },
            outLineStyle,
            sx,
          ] as SxProps
        }
        disableUnderline
        {...props}
      />
      {helperText && <FormHelperText error={error}> {helperText} </FormHelperText>}
    </>
  )
}

export { TextField as TextFieldOutlined } from '@mui/material'

export default TextField
