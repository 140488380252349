import { BrowserRouter } from 'react-router-dom'
import AppRouter from 'components/Core/AppRouter'
import { ThemeProvider } from '@mui/material'
import theme from 'styles/theme'

import { ToastProvider } from 'components/UI/Toast'
import { GlobalStyles } from 'styles'

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <AppRouter />
        </ToastProvider>

        <GlobalStyles />
      </ThemeProvider>
    </BrowserRouter>
  )
}
