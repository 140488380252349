import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import apiHandler from 'helpers/ApiHandler'
import { lookupResponse } from 'assets/mocks'
import { LookUpInfo } from 'redux/App/app.state'

const lookup = () =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<LookUpInfo>(lookupResponse) : apiHandler.getRequest<LookUpInfo>('lookup')

const utilsService = {
  lookup,
}

export default utilsService
