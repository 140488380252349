import {
  DialogProps as MuiDialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import { Button, ButtonProps } from 'components/Forms'
import { DialogStyled } from './Dialog.style'
import { ReactNode } from 'react'

export interface DialogProps extends MuiDialogProps {
  header: string
  description: ReactNode
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton') => void
  primaryButton?: ReactNode
  primaryButtonProps?: ButtonProps
}

export default function Dialog({ header, description, primaryButton, primaryButtonProps = {}, ...props }: DialogProps) {
  return (
    <DialogStyled {...props}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={(event) => {
            props.onClose(event, 'closeButton')
          }}
        >
          Close
        </Button>
        {primaryButton && <Button {...primaryButtonProps}>{primaryButton}</Button>}
      </DialogActions>
    </DialogStyled>
  )
}
