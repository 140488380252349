/* eslint-disable sonarjs/no-nested-conditional */
/* eslint-disable unicorn/no-nested-ternary */
/* eslint-disable no-nested-ternary */
import { Box, Typography, SxProps } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'
import { fontWeight } from 'styles/theme'
import { Tooltip } from 'components/UI'
import { TextField, Select } from 'components/Forms'

interface WidgetStateProps {
  title?: string
  value?: string | number | JSX.Element
  Icon?: SvgIconComponent
  isToolTip?: boolean
  toolTipValue?: JSX.Element
  componentWrapperStyles?: SxProps
  editState?: boolean
  inputType?: string
  inputField?: string
  product?: string
  fieldType?: string
  selectOption?: { label: string; value: string | number }[]
  onChangeValue?(
    product: string,
    field: string,
    value: string | number | unknown,
  ): void
}

const numberInputOnWheelPreventChange = (event) => {
  // Prevent the input value change
  event.target.blur()

  // Prevent the page/container scrolling
  event.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    event.target.focus()
  }, 0)
}

export default function WidgetCard({
  title,
  value,
  Icon,
  isToolTip = false,
  toolTipValue,
  componentWrapperStyles,
  editState = false,
  inputType,
  inputField,
  onChangeValue,
  product,
  fieldType,
  selectOption,
}: WidgetStateProps) {
  return (
    <Box
      sx={
        {
          ...widgetStyles.containerStyle,
          ...componentWrapperStyles,
        } as SxProps
      }
    >
      <Typography sx={widgetStyles.typographyStyle}>
        {Icon && <Icon sx={widgetStyles.iconStyle} />}
        {title}
      </Typography>
      {isToolTip === true ? (
        <Tooltip title={toolTipValue} disableFocusListener disableTouchListener>
          <Typography sx={{ ...widgetStyles.valueStyle }}>{value}</Typography>
        </Tooltip>
      ) : editState ? (
        fieldType === 'select' ? (
          <Select
            data={selectOption}
            value={value}
            size="small"
            variant="filled"
            onChange={(event) => {
              onChangeValue(product, inputField, event.target.value)
            }}
            sx={{
              minWidth: 100,
            }}
          />
        ) : product === 'flightPassenger' ? (
          <>
            <TextField
              type={inputType}
              name={`name_${inputField}`}
              value={value.toString().split('_')[0]}
              onChange={(event) => {
                onChangeValue(product, `name_${inputField}`, event.target.value)
              }}
              size="small"
              onWheel={numberInputOnWheelPreventChange}
              sx={{ width: '40%', mx: '5px' }}
            />
            <TextField
              type={inputType}
              name={`birthDate_${inputField}`}
              value={value.toString().split('_')[1]}
              onChange={(event) => {
                onChangeValue(
                  product,
                  `birthDate_${inputField}`,
                  event.target.value,
                )
              }}
              size="small"
              onWheel={numberInputOnWheelPreventChange}
              sx={{ width: '40%', mx: '5px' }}
            />
          </>
        ) : product === 'contactName' ? (
          <>
            <TextField
              type={inputType}
              name="first_name"
              value={value.toString().split('_')[0]}
              onChange={(event) => {
                onChangeValue('contact', `first_name`, event.target.value)
              }}
              size="small"
              onWheel={numberInputOnWheelPreventChange}
              sx={{ width: '40%', mx: '5px' }}
            />
            <TextField
              type={inputType}
              name="last_name"
              value={value.toString().split('_')[1]}
              onChange={(event) => {
                onChangeValue('contact', 'last_name', event.target.value)
              }}
              size="small"
              onWheel={numberInputOnWheelPreventChange}
              sx={{ width: '40%', mx: '5px' }}
            />
          </>
        ) : (
          <TextField
            type={inputType}
            name={inputField}
            value={value}
            onChange={(event) => {
              onChangeValue(product, inputField, event.target.value)
            }}
            size="small"
            onWheel={numberInputOnWheelPreventChange}
            sx={{ width: '50%' }}
          />
        )
      ) : (
        <Typography sx={{ ...widgetStyles.valueStyle }}>{value}</Typography>
      )}
    </Box>
  )
}

interface WidgetStylesType {
  containerStyle: SxProps
  typographyStyle: SxProps
  iconStyle: SxProps
  valueStyle: SxProps
}

const widgetStyles: WidgetStylesType = {
  containerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    py: '18px',
    borderBottom: '1px solid #dbd3d3',
  },
  iconStyle: { fontSize: '0.85rem', marginRight: '5px' },
  valueStyle: { fontWeight: fontWeight.bold, fontSize: '0.85rem' },
  typographyStyle: {
    fontSize: '0.85rem',
  },
}
