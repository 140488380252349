import { useCallback, useEffect, useState } from 'react'
import { selectLookupData } from 'redux/App/app.selectors'
import { Domain, ModulePermission, PermissionTypes, User } from 'redux/Auth/auth.state'
import { useAppSelector } from 'redux/hooks'

export interface DomainChecked extends Domain {
  checked: boolean
}

export const useEditCrmData = (data?: User) => {
  const lookupData = useAppSelector(selectLookupData)
  const [domains, setDomains] = useState<DomainChecked[]>([])
  const [permissions, setPermissions] = useState<ModulePermission[]>([])

  const onDomainChange = useCallback(
    (index: number, checked: boolean) => {
      const newDomains = [...domains]
      newDomains[index].checked = checked
      setDomains(newDomains)
    },
    [domains],
  )

  const onPermissionChange = useCallback(
    (index: number, type: keyof PermissionTypes, checked: boolean) => {
      const newPermissions = [...permissions]
      newPermissions[index][type] = checked
      setPermissions(newPermissions)
    },
    [permissions],
  )

  useEffect(() => {
    // set domains
    const domainsWithPermission = lookupData.domains.map((domain) => ({
      ...domain,
      checked: data ? data.userDomains.some((userDomain) => userDomain.domain.id === domain.id) : false,
    }))

    setDomains(domainsWithPermission)

    // set permissions
    const modulesWithPermission = lookupData.modules.map((module) => {
      const userModule = data?.permissions.find((userModule) => userModule.module.id === module.id)
      return {
        module,
        read: userModule?.read ?? false,
        write: userModule?.write ?? false,
        delete: userModule?.delete ?? false,
        canViewPaymentData: userModule?.canViewPaymentData ?? false,
        canViewUserData: userModule?.canViewUserData ?? false,
      }
    })

    setPermissions(modulesWithPermission)
  }, [data, lookupData])

  return {
    domains,
    permissions,
    setPermissions,
    onDomainChange,
    onPermissionChange,
  }
}
