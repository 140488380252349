import useToast from 'components/UI/Toast'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import appActions from 'redux/App/app.action'
import { utilsService } from 'services'

export const useGlobalData = () => {
  const dispatch = useDispatch()
  const toast = useToast()

  const fetchLookupData = useCallback(async () => {
    try {
      const { response } = await utilsService.lookup()
      dispatch(appActions.setLookupData(response.data))
    } catch {
      toast.error()
    }
  }, [dispatch, toast])

  useEffect(() => {
    fetchLookupData()
  }, [fetchLookupData])
}
