import {
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItemProps,
  SxProps,
} from '@mui/material'
import { COLORS } from 'styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { fontWeight } from 'styles/theme'

export type SelectData = {
  value: number | string
  label: string
}

export type SelectProps = MuiSelectProps & {
  data: SelectData[]
  menuItemProps?: MenuItemProps
}

export default function Select({
  data,
  menuItemProps,
  variant,
  sx,
  ...otherProps
}: SelectProps) {
  const { MenuProps, ...restProps } = otherProps

  let selectStyles: SxProps = {}

  if (variant === 'filled') {
    selectStyles = {
      backgroundColor: COLORS.PRIMARY_SUPER_LIGHT,
      color: COLORS.PRIMARY,
      border: 0,
      fontWeight: fontWeight.medium,
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '0 !important',
      },
      '.MuiSelect-icon': {
        color: COLORS.PRIMARY,
      },
    }
  }

  return (
    <MuiSelect
      sx={[selectStyles, sx] as SxProps}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.1)',
          },
          ...MenuProps?.sx,
        },
        ...MenuProps,
      }}
      IconComponent={ExpandMoreIcon}
      {...restProps}
    >
      {data.map((dataItem) => (
        <MenuItem
          value={dataItem.value}
          key={dataItem.value}
          {...menuItemProps}
        >
          {dataItem.label}
        </MenuItem>
      ))}
    </MuiSelect>
  )
}
