import {
  Chart,
  ChartOptionsProps,
  ChartRef,
  chartBaseOptions,
} from 'components/UI'
import { useRef, useState, useEffect } from 'react'

interface ColumnChartProps extends ChartOptionsProps {
  /**
   * creates stacked bar chart
   * @default false
   */
  stacked?: boolean

  /**
   * enables data labels on top of column
   * @default false
   */
  dataLabels?: boolean
}

export default function ColumnChart(props: ColumnChartProps) {
  const [chartData, setChartData] = useState(chartOptions(props))

  const chartComponent = useRef<ChartRef>()

  useEffect(() => {
    setChartData(chartOptions(props))
    chartComponent?.current?.chart.reflow()
  }, [props])

  return <Chart ref={chartComponent} options={{ ...chartData }} />
}

const chartOptions = ({
  stacked = false,
  dataLabels = false,
  ...options
}: ColumnChartProps) =>
  chartBaseOptions('column', options, {
    tooltip: {
      enabled: true,
      formatter(this) {
        return `<b>${this.x}</b>: ${options?.isRevenue ? '$' : ''}${Math.round(this.y)}
        `
      },
    },
    plotOptions: {
      column: {
        stacking: stacked ? 'normal' : undefined,
        pointPadding: 0.1,
        borderWidth: 0,
        borderRadius: stacked ? 0 : 5,
        dataLabels: {
          enabled: dataLabels,
          useHTML: true,
          format:
            '<span style="font-size:10px; font-weight:normal">{Math.round((point.y)} </span>',
        },
      },
    },
    legend: {
      enabled: options.series.length > 1,
    },
  })
