import { Card } from 'components/UI'

import { Box, Typography } from '@mui/material'
import { COLORS } from 'styles'
import { SvgIconComponent } from '@mui/icons-material'
import { useMemo } from 'react'
import { CHART_THEME } from 'helpers/Constants'
import { fontWeight } from 'styles/theme'
import DateRangePicker, {
  DateRangePickerProps,
} from 'components/Forms/DateRangePicker/DateRangePicker'

interface SimpleStateProps {
  /**
   * icon that displays on top left
   */
  Icon?: SvgIconComponent

  /**
   * primary state
   */
  state: string | number

  /**
   * label identifying information
   */
  label: string

  /**
   * theme for the card
   * @default 'primary'
   */
  theme?: (typeof CHART_THEME)[keyof typeof CHART_THEME]
  /**
   * enables date range picker
   * @default false
   */
  enableDateRange?: boolean
  /**
   * Callback is fired when user changes date range
   */
  onDateRangeChange?: () => void
  /**
   * Date range props
   */
  dateRangeProps?: DateRangePickerProps
}

export default function SimpleState({
  Icon,
  state,
  label,
  theme = CHART_THEME.PRIMARY,
  enableDateRange = false,
  dateRangeProps,
}: SimpleStateProps) {
  const fontColor = useMemo(() => {
    let color = ''
    switch (theme) {
      case CHART_THEME.SECONDARY: {
        color = COLORS.SECONDARY
        break
      }

      case CHART_THEME.TERTIARY: {
        color = COLORS.TERTIARY
        break
      }

      case CHART_THEME.DEEP_SAFFRON: {
        color = COLORS.DEEP_SAFFRON
        break
      }

      case CHART_THEME.DARK: {
        color = COLORS.WHITE
        break
      }

      default: {
        color = COLORS.PRIMARY
        break
      }
    }

    return color
  }, [theme])

  return (
    <Card
      sx={
        theme === CHART_THEME.DARK && {
          bgcolor: COLORS.DARK_BACKGROUND,
        }
      }
    >
      {Icon && (
        <Box>
          <Icon
            sx={{
              fontSize: 40,
              color: fontColor,
            }}
          />
        </Box>
      )}

      {enableDateRange && (
        <Box sx={{ float: 'right' }}>
          <DateRangePicker {...dateRangeProps} />
        </Box>
      )}

      <Box>
        <Typography
          sx={{
            color: fontColor,
            fontSize: 30,
            fontWeight: fontWeight.boldest,
          }}
        >
          {state}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            color:
              theme === CHART_THEME.DARK ? COLORS.WHITE : COLORS.SLATE_GREY,
            fontSize: 16,
            fontWeight: fontWeight.medium,
          }}
        >
          {label}
        </Typography>
      </Box>
    </Card>
  )
}
