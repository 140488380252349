import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { dashboardResponse, dashboardAnalyticsResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'

const dashboard = () => (SHOULD_MOCK_APIS ? apiHandler.mockApi(dashboardResponse) : apiHandler.getRequest('dashboard'))

const dashboardAnalytics = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi(dashboardAnalyticsResponse) : apiHandler.getRequest('dashboardAnalytics', data)

const dashboardService = {
  dashboard,
  dashboardAnalytics,
}

export default dashboardService
