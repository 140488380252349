import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { forwardRef, ForwardedRef } from 'react'
import { mergeObject } from 'helpers/Methods'
import { CHART_COLORS } from 'styles/colors'

export type ChartRef = HighchartsReact.RefObject

export type PieChartDataPoint = {
  name: string
  y: number
  visible: boolean
}

export type SeriesData = {
  /**
   * name of series
   */
  name: string

  /**
   * color for particular series
   */
  color?: string

  /**
   * data points as number array or pie chart points
   */
  data: number[] | PieChartDataPoint[]

  colorByPoint?: boolean

  innerSize?: string
}

export interface ChartProps extends HighchartsReact.Props {}

const Chart = forwardRef((props: ChartProps, ref: ForwardedRef<ChartRef>) => (
  <HighchartsReact ref={ref} highcharts={Highcharts} {...props} />
))

export default Chart

export interface ChartOptionsProps extends ChartProps {
  /**
   * title that will appear on x axis legend
   * @default blank
   */
  xAxisTitle?: string

  /**
   * title that will appear on y axis legend
   * @default blank
   */
  yAxisTitle?: string

  /**
   * labels that appear on y axis
   */
  xAxisLabels: string[]

  /**
   * series data
   */
  series: SeriesData[]
}

export const chartBaseOptions = (
  type: string,
  {
    xAxisTitle = '',
    yAxisTitle = '',
    xAxisLabels = [],
    series = [],
    ...rest
  }: ChartOptionsProps,
  additionalSettings?: ChartProps,
) => {
  const mergedAdditionalSettings = mergeObject(rest, additionalSettings ?? {})

  return mergeObject(
    {
      chart: {
        type,
      },
      credits: false,
      title: {
        text: '',
      },
      xAxis: {
        categories: xAxisLabels,
        title: {
          text: xAxisTitle,
        },

        crosshair: false,
        lineColor: 'transparent',
        tickLength: 0,
      },
      yAxis: {
        title: {
          text: yAxisTitle,
        },
        gridLineWidth: 0,
      },
      tooltip: {
        shared: true,
      },
      colors: CHART_COLORS,

      series,
    },
    mergedAdditionalSettings,
  )
}
