import {
  Dashboard as DashboardIcon,
  Analytics as AnalyticsIcon,
  PieChartOutlineRounded as PieChartIcon,
  LocalGroceryStore as ShopIcon,
  Newspaper as NewspaperIcon,
  Group as GroupIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
} from '@mui/icons-material'
import { PATHS } from 'helpers/Constants'
import Dashboard from 'pages/Dashboard'
import Orders from 'pages/Orders'
import NewsLetterSubscribers from 'pages/NewsLetterSubscribers'
import Users from 'pages/Users/Users' // todo
import CustomerAnalytics from 'pages/CustomerAnalytics'
import { MODULES } from 'helpers/Constants'
import CRMUsers from 'pages/CRMUsers'

const sidebarLinks = [
  {
    name: 'Dashboard',
    icon: DashboardIcon,
    to: PATHS.HOME,
    component: Dashboard,
    module: MODULES.DASHBOARD,
  },
  {
    name: 'Orders',
    icon: ShopIcon,
    to: PATHS.ORDERS,
    path: PATHS.ORDERS_WRAPPER,
    component: Orders,
    module: MODULES.ORDERS,
  },
  {
    name: 'Customer Analytics',
    icon: PieChartIcon,
    to: PATHS.CUSTOMER,
    path: PATHS.CUSTOMER_WRAPPER,
    component: CustomerAnalytics,
    module: MODULES.CUSTOMER_ANALYTICS,
  },
  {
    name: 'User Analytics',
    icon: AnalyticsIcon,
    to: PATHS.USER_ANALYTICS,
    path: PATHS.USER_ANALYTICS_WRAPPER,
    component: Users,
    module: MODULES.USER_ANALYTICS,
  },
  {
    name: 'Subscribers',
    icon: NewspaperIcon,
    to: PATHS.SUBSCRIBERS,
    component: NewsLetterSubscribers,
    module: MODULES.SUBSCRIBERS,
  },
  {
    name: 'Users',
    icon: GroupIcon,
    to: PATHS.CRM_USERS,
    path: PATHS.CRM_USERS_WRAPPER,
    component: Users,
    module: MODULES.USERS,
  },
  {
    name: 'CRM Users',
    icon: AdminPanelSettingsIcon,
    to: PATHS.CRM_USERS,
    path: PATHS.CRM_USERS_WRAPPER,
    component: CRMUsers,
    module: MODULES.CRM_USERS,
  },

  // {
  //   name: 'Contacts',
  //   icon: ContactsIcon,
  //   to: PATHS.'/contacts',
  //   component: Users,
  // },
  // {
  //   name: 'CRM Users',
  //   icon: ManageAccountsOutlinedIcon,
  //   to: PATHS.'/crm-users',
  //   component: Users,
  // },
]

export default sidebarLinks
