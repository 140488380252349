import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
// import authenticationService from 'services/authentication.service'
import { styled, SxProps } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { Toolbar, MenuItem, Menu, Avatar, Divider, ListItemIcon, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Logout, Settings } from '@mui/icons-material'
import { COLORS } from 'styles'
import { DRAWER_WIDTH } from 'helpers/Constants'
import authActions from 'redux/Auth/auth.action'
// import { ReactComponent as BSLogo } from 'assets/Logo/BookSeats_Logo.svg'
import { IconButton } from 'components/Forms/Button'
import { selectUser } from 'redux/Auth/auth.selectors'
import headerStyles from './Header.style'
import useToast from 'components/UI/Toast'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
  drawerWidth?: number
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

interface HeaderProps {
  open: boolean
  handleDrawerOpen: () => void
}

function Header({ open, handleDrawerOpen }: HeaderProps) {
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector(selectUser)
  const toast = useToast()
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)

  const menuOpen = Boolean(anchorElement)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }
  const handleLogout = async () => {
    try {
      dispatch(authActions.userLogout())
    } catch {
      toast.error('Error Processing your request!')
    }
  }

  return (
    <AppBar
      position="fixed"
      open={open}
      sx={[headerStyles.headerSection, !open && headerStyles.headerSectionFull] as SxProps}
    >
      <Toolbar sx={[headerStyles.toolbar, open && headerStyles.toolbarFull] as SxProps}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 2,
          }}
        >
          <MenuIcon sx={{ color: '#000' }} />
        </IconButton>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32, bgcolor: COLORS.DARK_BLUE }}>
              {userInfo?.name.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorElement}
          id="account-menu"
          open={menuOpen}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Header
