import { Box, InputAdornment, Paper } from '@mui/material'
import { useFormik } from 'formik'
import { Button, TextField } from 'components/Forms'
import { MailOutline } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { VERIFY_CODE_ROUTE } from 'helpers/Constants/path'
import { ReactComponent as ReactLogo } from 'assets/Logo/logo.svg'
import authenticationService from 'services/authentication.service'
import loginStyles from './Login.style'
import { formFieldsConfig, FORM_FIELDS, validationSchema } from './Login.schema'
import useToast from 'components/UI/Toast'
import { useState } from 'react'

interface LoginObject {
  email: string
}

function Login() {
  const navigate = useNavigate()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: formFieldsConfig.initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => onSubmit(values),
    validateOnMount: true,
  })

  const onSubmit = async (data: LoginObject) => {
    formik.setSubmitting(true)
    setIsLoading(true)
    try {
      const { response } = await authenticationService.login(data.email)
      toast.success(response.message)
      navigate(`${VERIFY_CODE_ROUTE}/${response.data.code}`)
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      formik.setSubmitting(false)
      setIsLoading(false)
    }
  }

  const getFormikFields = (field: string) => ({
    value: formik.values[field],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    error: formik.touched[field] && Boolean(formik.errors[field]),
    helperText: formik.touched[field] && formik.errors[field],
  })

  return (
    <Box sx={loginStyles.pageWrapper}>
      <Paper sx={loginStyles.loginSection}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={loginStyles.logoContainer}>
            <ReactLogo className="bs-logo" />
          </Box>

          <Box sx={loginStyles.inputsWrapper}>
            <Box sx={loginStyles.inputWrapper}>
              <TextField
                {...formFieldsConfig.email}
                {...getFormikFields(FORM_FIELDS.email)}
                endAdornment={
                  <InputAdornment position="end">
                    <MailOutline />
                  </InputAdornment>
                }
                fullWidth
              />
            </Box>

            <Box sx={loginStyles.submitContainer}>
              <Button type="submit" fullWidth disabled={formik.isSubmitting || !formik.isValid} loading={isLoading}>
                {LABEL.submitButtonTitle}
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  )
}

const LABEL = {
  forgotPassword: 'Forgot Password',
  submitButtonTitle: 'Login',
}

export default Login
