import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { loginMockResponse, verifyCodeResponse, resendCodeResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'
import { User } from 'redux/Auth/auth.state'

interface LoginResponse {
  code: string
}
const login = (email: string) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<LoginResponse>(loginMockResponse)
    : apiHandler.postRequest<LoginResponse>('login', { data: { email } })

interface VerifyResponse {
  token: string
  user: User
}
const verifyAuthentication = (otp: number, code: string) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<VerifyResponse>(verifyCodeResponse)
    : apiHandler.postRequest<VerifyResponse>('login/verify', {
        data: {
          otp,
          code,
        },
      })

const resendAuthenticationCode = (code: string) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<{}>(resendCodeResponse)
    : apiHandler.postRequest<{}>('login/resend-code', {
        data: {
          code,
        },
      })

const authenticationService = {
  login,
  verifyAuthentication,
  resendAuthenticationCode,
}

export default authenticationService
