import { css, Global } from '@emotion/react'
import { fontFamily } from './theme'

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'

import './global.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import COLORS from './colors'

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        html,
        body {
          margin: 0;
          padding: 0;
          font-family: ${fontFamily};
          background-color: ${COLORS.LIGHT_GREY} !important;
        }

        * {
          box-sizing: border-box;
        }

        .f-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .full-h {
          height: 100%;
        }
      `}
    />
  )
}
