/* eslint-disable sonarjs/deprecation */
import { Grid } from '@mui/material'
import {
  Star as StarIcon,
  Reviews as ReviewsIcon,
  Difference as DifferenceIcon,
  Style as StyleIcon,
  LocalAtm as LocalAtmIcon,
  PeopleOutline as PeopleOutlineIcon,
  NightsStay as NightsStayIcon,
  KingBed as KingBedIcon,
} from '@mui/icons-material'
import { SimpleStateCard } from 'components/UI'
import { ObjectOf } from 'helpers/Types'

interface TopStatsProps {
  data: ObjectOf<string>
}

export default function TopStats({ data }: TopStatsProps) {
  return (
    <Grid sx={{ mt: 1 }} container spacing={2}>
      {cards.map((card) => (
        <Grid sm={3} xs={6} item key={card.valueKey}>
          <SimpleStateCard
            title={card.title}
            Icon={card.icon}
            value={`${card?.isRevenue ? '$' : ''} ${data[card.valueKey] ?? ''}`}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const cards = [
  {
    title: 'Average Hotel Stars',
    icon: StarIcon,
    valueKey: 'avgHotelStars',
  },
  {
    title: 'Average Review Rating',
    icon: ReviewsIcon,
    valueKey: 'avgReviewRating',
  },
  {
    title: 'Average  Order/Event Gap',
    icon: DifferenceIcon,
    valueKey: 'orderEventGap',
  },
  {
    title: 'Average Ticket Per Order',
    icon: StyleIcon,
    valueKey: 'quantityPerOrder',
  },
  {
    title: 'Average Ticket Price',
    icon: LocalAtmIcon,
    valueKey: 'averagePrice',
    isRevenue: true,
  },
  {
    title: 'Average Age',
    icon: PeopleOutlineIcon,
    valueKey: 'avgAge',
  },
  {
    title: 'Average # Nights',
    icon: NightsStayIcon,
    valueKey: 'avgNights',
  },
  {
    title: 'Average # Rooms',
    icon: KingBedIcon,
    valueKey: 'avgRooms',
  },
]
