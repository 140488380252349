import { CircularProgress, SxProps } from '@mui/material'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { CSSProperties, useMemo } from 'react'
import { ButtonStyled } from './Button.style'

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean
}

function Button({ sx, loading, disabled, variant = 'contained', ...props }: ButtonProps) {
  const shadowStyles = useMemo(() => {
    const styles: CSSProperties = {}

    if (variant === 'contained') {
      styles.boxShadow = 'rgba(0, 158, 247, 0.3) 0px 2px 6px 0px'

      if (props.color === 'error') styles.boxShadow = 'rgba(211, 47, 47, 0.3) 0px 2px 6px 0px'
      else if (props.color === 'secondary') styles.boxShadow = 'rgba(241, 65, 108, 0.3) 0px 2px 6px 0px'
    }

    return styles
  }, [props.color, variant])
  return (
    <ButtonStyled
      sx={
        [
          {
            borderRadius: '5px',
            paddingY: 1,
            paddingX: 2,
            textTransform: 'none',
            fontSize: '14px',
            boxShadow: shadowStyles.boxShadow,
            minWidth: 100,

            '&:hover': {
              boxShadow: shadowStyles.boxShadow,
            },
          },
          sx,
        ] as SxProps
      }
      size="small"
      variant={variant}
      endIcon={loading && <CircularProgress size={'20px'} sx={{ ml: 2 }} />}
      disabled={loading || disabled}
      {...props}
    />
  )
}

export default Button
