import { Box, InputAdornment, Paper, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { Button, TextField } from 'components/Forms'
import { Pin } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { LOGIN_ROUTE } from 'helpers/Constants/path'
import { ReactComponent as ReactLogo } from 'assets/Logo/logo.svg'
import authenticationService from 'services/authentication.service'
import authActions from 'redux/Auth/auth.action'
import { useAppDispatch } from 'redux/hooks'
import VerifyCodeFormStyles from './VerifyCde.style'
import { formFieldsConfig, FORM_FIELDS, validationSchema } from './verifyCode.schema'
import useToast from 'components/UI/Toast'
import { useState } from 'react'
import loginStyles from '../Login/Login.style'
import { Link } from 'components/Navigation'

interface VerifyCodeObject {
  otp: number
}

function VerifyCode() {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { code } = useParams()
  const [resendLoading, setResendLoading] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)

  const formik = useFormik({
    initialValues: formFieldsConfig.initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      submit(values)
    },
    validateOnMount: true,
  })

  const submit = async (data: VerifyCodeObject) => {
    setVerifyLoading(true)
    try {
      const { response } = await authenticationService.verifyAuthentication(data.otp, code)
      dispatch(authActions.updateAuthState(response.data))
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      setVerifyLoading(false)
    }
  }

  const resendAuthCode = async () => {
    setResendLoading(true)
    try {
      const { response } = await authenticationService.resendAuthenticationCode(code)
      toast.success(response.message)
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      setResendLoading(false)
    }
  }

  const getFormikFields = (field: string) => ({
    value: formik.values[field],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    error: formik.touched[field] && Boolean(formik.errors[field]),
    helperText: formik.touched[field] && formik.errors[field],
  })

  return (
    <Box sx={VerifyCodeFormStyles.pageWrapper}>
      <Paper sx={VerifyCodeFormStyles.verifyCodeSection}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={VerifyCodeFormStyles.titleContainer} py={1}>
            <ReactLogo className="bs-logo" />
          </Box>
          <Box py={1}>
            <TextField
              {...formFieldsConfig.otp}
              {...getFormikFields(FORM_FIELDS.otp)}
              endAdornment={
                <InputAdornment position="end">
                  <Pin />
                </InputAdornment>
              }
              fullWidth
            />
          </Box>

          <Typography sx={loginStyles.forgotPasswordContainer}>
            <Link href={LOGIN_ROUTE} sx={loginStyles.forgotPasswordLink}>
              {LABEL.toLogin}
            </Link>
          </Typography>

          <Box sx={VerifyCodeFormStyles.submitContainer}>
            <Button
              type="button"
              color="secondary"
              onClick={resendAuthCode}
              disabled={verifyLoading}
              loading={resendLoading}
            >
              {LABEL.resendButtonTitle}
            </Button>
            <Button type="submit" disabled={!formik.isValid || resendLoading} loading={verifyLoading}>
              {LABEL.submitButtonTitle}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  )
}

const LABEL = {
  submitButtonTitle: 'Verify Code',
  resendButtonTitle: 'Resend',
  toLogin: 'Back to Login',
}

export default VerifyCode
