import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Drawer,
  Typography,
  DrawerProps,
} from '@mui/material'
import { ReactComponent as BSLogo } from 'assets/Logo/logo.svg'
import { CRM } from 'helpers/Constants/path'
import { useLocation } from 'react-router-dom'
import { useCRMNavigate } from 'helpers/Hooks'
import { useGetPermitted } from 'components/Core/Permissions'
import sidebarStyles from './Sidebar.style'

export interface SidebarProps extends DrawerProps {
  onClose: () => void
}

function Sidebar({ open }: SidebarProps) {
  const { pathname } = useLocation()
  const navigate = useCRMNavigate()
  const { permittedLinks } = useGetPermitted()

  const isCurrentPath = (path: string) => path === pathname || (path !== CRM && pathname.startsWith(path))

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={[sidebarStyles.drawer, open && sidebarStyles.drawerOpen, !open && sidebarStyles.drawerClose] as SxProps}
    >
      <Box sx={sidebarStyles.drawerHeader}>
        {open && (
          <>
            <BSLogo className="bs-logo" />
            {/* <IconButton onClick={onClose}>
              <ChevronLeftIcon />
            </IconButton> */}
          </>
        )}
      </Box>

      {/* <Divider /> */}

      <List sx={sidebarStyles.sidebarItemWrapper}>
        {permittedLinks.map((link) => {
          const isActive = isCurrentPath(CRM + link.to)
          return (
            <ListItem key={link.name} sx={sidebarStyles.sidebarItem} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(link.to)
                }}
                sx={
                  [
                    sidebarStyles.sidebarItemButton,
                    open && sidebarStyles.sidebarItemButtonOpen,
                    isActive && sidebarStyles.sidebarItemButtonActive,
                  ] as SxProps
                }
              >
                <ListItemIcon sx={sidebarStyles.sidebarItemIconWrapper}>
                  <link.icon
                    sx={[sidebarStyles.sidebarItemIcon, isActive && sidebarStyles.sidebarItemTextActive] as SxProps}
                  />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <Typography
                    sx={[sidebarStyles.sidebarItemLink, isActive && sidebarStyles.sidebarItemTextActive] as SxProps}
                  >
                    {link.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  )
}

export default Sidebar
