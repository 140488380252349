export const DOMAINS = {
  BOOKSEATS: 'Bookseats',
  PACKERS: 'Packers',
  WILD: 'Wild',
  JETS: 'New York Jets',
  UTES: 'Utes',
  HURRICANES: 'Hurricanes',
  VEGAS: 'Vegas Golden Knights',
  BRONCOS: 'Denver Broncos',
} as const
export type Domains = (typeof DOMAINS)[keyof typeof DOMAINS]

export const MODULES = {
  DASHBOARD: 'Dashboard',
  ORDERS: 'Orders',
  CUSTOMER_ANALYTICS: 'Customer Analytics',
  USER_ANALYTICS: 'User Analytics',
  SUBSCRIBERS: 'Subscribers',
  USERS: 'Users',
  CRM_USERS: 'CRM Users',
} as const
export type Modules = (typeof MODULES)[keyof typeof MODULES]

export const ACCESS_TYPES = {
  READ: 'read',
  WRITE: 'write',
  DELETE: 'delete',
  PAYMENT_DATA: 'canViewPaymentData',
  USER_DATA: 'canViewUserData',
} as const
export type AccessTypes = (typeof ACCESS_TYPES)[keyof typeof ACCESS_TYPES]
