import { LOGIN_ROUTE, VERIFY_CODE_ROUTE, HOME, CRM } from 'helpers/Constants/path'
import { Login, VerifyCode } from 'pages/Auth'
import { Routes, Route, Navigate } from 'react-router-dom'
import { PrivateRoutes, PublicRoutes } from 'routes'
import MainWrapper from 'components/Core/MainWrapper'
import Global from 'components/Core/Global'
import { useGetPermitted } from 'components/Core/Permissions'

export default function AppRouter() {
  const { permittedLinks } = useGetPermitted()

  return (
    <Routes>
      <Route
        path={`${CRM}/*`}
        element={
          <PrivateRoutes>
            <MainWrapper>
              <Routes>
                {permittedLinks.map((link) => (
                  <Route path={link.path ?? link.to} element={<link.component />} key={link.to} />
                ))}
              </Routes>
            </MainWrapper>

            <Global />
          </PrivateRoutes>
        }
      />

      <Route
        path={`${HOME}*`}
        element={
          <PublicRoutes>
            <Routes>
              <Route path={LOGIN_ROUTE} element={<Login />} />
              <Route path={`${VERIFY_CODE_ROUTE}/:code`} element={<VerifyCode />} />
              <Route path="*" element={<Navigate to={LOGIN_ROUTE} />} />
            </Routes>
          </PublicRoutes>
        }
      />
    </Routes>
  )
}
