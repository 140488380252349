import { RightSideBar, RightSidebarProps } from 'components/Navigation/Sidebar'
import { componentsStyles as styles } from './Components.styles'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid2 as Grid, Typography } from '@mui/material'
import { Card } from 'components/UI'
import { DomainItem, formFieldsConfig, LABELS } from './Components'
import { TextFieldOutlined } from 'components/Forms/TextField'
import { useFormik } from 'formik'
import { DomainChecked } from './EditCrmUser.controller'
import { ModulePermission, PermissionTypes } from 'redux/Auth/auth.state'
import { permissionLabels } from 'helpers/Strings/labels'

interface UserInfo {
  name: string
  email: string
}

interface AddUserDrawerProps extends RightSidebarProps {
  loading: boolean
  onSave: () => void
  domains: DomainChecked[]
  onDomainChange: (index: number, checked: boolean) => void
  permissions: ModulePermission[]
  onPermissionChange: (index: number, type: keyof PermissionTypes, checked: boolean) => void
  formik: ReturnType<typeof useFormik<UserInfo>>
}
export default function AddUserDrawer({
  onSave,
  loading,
  domains,
  onDomainChange,
  permissions,
  onPermissionChange,
  formik,
  ...props
}: AddUserDrawerProps) {
  const getFormikFields = (field: string) => ({
    value: formik.values[field],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    error: formik.touched[field] && Boolean(formik.errors[field]),
    helperText: formik.touched[field] && formik.errors[field],
  })

  return (
    <RightSideBar
      bodyStyles={styles.wrapper}
      footerActions={[
        {
          label: 'Save',
          type: 'submit',
          onClick: onSave,
          loading,
        },
      ]}
      {...props}
    >
      <Typography sx={styles.sectionHeading}>{LABELS.userInfo}</Typography>
      <Card sx={styles.sectionCard}>
        <Box sx={styles.userInfoContainer}>
          <TextFieldOutlined {...formFieldsConfig.name} {...getFormikFields('name')} size="small" fullWidth />
          <TextFieldOutlined {...formFieldsConfig.email} {...getFormikFields('email')} size="small" fullWidth />
        </Box>
      </Card>

      {/* DOMAINS */}
      <Typography sx={styles.sectionHeading}>{LABELS.domains}</Typography>
      <Card sx={styles.sectionCard}>
        <FormGroup>
          {domains.map((domain, index) => (
            <FormControlLabel
              control={<Checkbox />}
              label={<DomainItem domain={domain} />}
              checked={domain.checked}
              onChange={(_event, checked) => {
                onDomainChange(index, checked)
              }}
              key={domain.id}
            />
          ))}
        </FormGroup>
      </Card>

      {/* Permissions */}
      <Typography sx={styles.sectionHeading}>{LABELS.permissions}</Typography>

      {permissions.map((permission, index) => (
        <Card sx={styles.sectionCard} key={permission.module.id}>
          <Typography sx={styles.sectionCardHeading}>{permission.module.name}</Typography>
          <FormGroup>
            <Grid container>
              <PermissionItem permission={permission} type={'read'} onChange={onPermissionChange} index={index} />
              <PermissionItem permission={permission} type="write" onChange={onPermissionChange} index={index} />
              <PermissionItem permission={permission} type="delete" onChange={onPermissionChange} index={index} />
              <PermissionItem
                permission={permission}
                type="canViewPaymentData"
                onChange={onPermissionChange}
                index={index}
              />
              <PermissionItem
                permission={permission}
                type="canViewUserData"
                onChange={onPermissionChange}
                index={index}
              />
            </Grid>
          </FormGroup>
        </Card>
      ))}
    </RightSideBar>
  )
}

function PermissionItem({
  permission,
  onChange,
  index,
  type,
}: {
  permission: ModulePermission
  index: number
  onChange: (index: number, type: keyof PermissionTypes, checked: boolean) => void
  type: keyof PermissionTypes
}) {
  return (
    <Grid size={4}>
      <FormControlLabel
        control={<Checkbox />}
        label={<Typography sx={styles.permissionLabel}>{permissionLabels[type]}</Typography>}
        checked={permission[type]}
        onChange={(_event, checked) => {
          onChange(index, type, checked)
        }}
      />
    </Grid>
  )
}
