import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { newsLetterSubscribersResponse, exportNewsLetterResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'

const newsLetterSubscribers = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(newsLetterSubscribersResponse)
    : apiHandler.getRequest('newsLetterSubscribers', data)

const exportNewsLetterSubscribers = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi(exportNewsLetterResponse) : apiHandler.getRequest('exportNewsletter', data)

const newsLetterSubscribersService = {
  newsLetterSubscribers,
  exportNewsLetterSubscribers,
}

export default newsLetterSubscribersService
