import {
  gridPageCountSelector,
  GridPagination,
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import { SxProps, TablePaginationProps, Pagination as MuiPagination } from '@mui/material'
import { fontWeight } from 'styles/theme'
import { COLORS } from 'styles'

export interface DataGridProps extends MuiDataGridProps {}

export default function DataGrid({ columns, sx, paginationModel, slots, ...otherProps }: DataGridProps) {
  const columnsWithDefaultValue = columns.map(({ flex, width, ...column }) => ({
    flex: flex ?? (width ? 0 : 1),
    width,
    editable: false,
    sortable: false,
    ...column,
  }))

  return (
    <MuiDataGrid
      columns={columnsWithDefaultValue}
      sx={
        [
          {
            width: '100%',
            borderWidth: 0,
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },

            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: fontWeight.bold,
              color: COLORS.LIGHT_TEXT,
              paddingY: 1.5,
            },

            '& .MuiDataGrid-cellContent': {
              fontWeight: fontWeight.medium,
            },

            '& .MuiDataGrid-virtualScroller': {
              overflow: 'hidden',
            },
          },
          sx,
        ] as SxProps
      }
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
        },
      }}
      slots={{
        pagination: CustomPagination,
      }}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      paginationMode="server"
      disableColumnMenu
      disableColumnSelector
      disableRowSelectionOnClick
      {...otherProps}
    />
  )
}

export { type GridColDef } from '@mui/x-data-grid'

function CustomPagination(props: any) {
  return (
    <GridPagination
      ActionsComponent={Pagination}
      slots={{
        pagination: CustomPagination,
      }}
      {...props}
    />
  )
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext()
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1)
      }}
    />
  )
}
