import { SnackbarProvider } from 'notistack'
import { PropsWithChildren } from 'react'

export default function ToastProvider(props: PropsWithChildren) {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      {...props}
    />
  )
}
