import { object, string } from 'yup'

export const FORM_FIELDS = {
  email: 'email',
}

export const formFieldsConfig = {
  initialValues: {
    email: '',
    password: '',
  },
  email: {
    placeholder: 'Email',
    name: FORM_FIELDS.email,
    type: 'email',
  },
}

export const validationSchema = object().shape({
  email: string().email().required('Please enter email id.'),
})
