import { RightSideBar, RightSidebarProps } from 'components/Navigation/Sidebar'
import { componentsStyles as styles } from './Components.styles'
import { Box, Typography } from '@mui/material'
import { MailIcon } from 'components/UI/Icons'
import { Card } from 'components/UI'
import { User } from 'redux/Auth/auth.state'
import { DomainItem, LABELS, PermissionView } from './Components'

interface ViewCrmUserProps extends Omit<RightSidebarProps, 'headerText'> {
  data: User
}

export default function ViewCrmUser({ data, ...props }: ViewCrmUserProps) {
  if (!data) return
  return (
    <RightSideBar headerText={data.name} bodyStyles={styles.wrapper} {...props}>
      <Box sx={styles.userEmail}>
        <MailIcon sx={styles.emailIcon} />
        <Typography>{data.email}</Typography>
      </Box>

      {/* DOMAINS */}
      {data.userDomains.length > 0 && (
        <>
          <Typography sx={styles.sectionHeading}>{LABELS.domains}</Typography>
          <Card sx={styles.sectionCard}>
            {data.userDomains.map((domain) => (
              <DomainItem domain={domain.domain} key={domain.id} />
            ))}
          </Card>
        </>
      )}

      {/* Permissions */}
      {data.permissions.length > 0 && (
        <>
          <Typography sx={styles.sectionHeading}>{LABELS.permissions}</Typography>

          {data.permissions.map((permission) => (
            <Card sx={styles.sectionCard} key={permission.id}>
              <Typography sx={styles.sectionCardHeading}>{permission.module.name}</Typography>
              <PermissionView permission={permission} />
            </Card>
          ))}
        </>
      )}
    </RightSideBar>
  )
}
