/* eslint-disable sonarjs/deprecation */
import { Box, Grid, SxProps } from '@mui/material'
import {
  ColumnChartWidget,
  PageTitle,
  PieChartWidget,
  ScatterChartWidget,
  SeriesData,
  SplineChartWidget,
  TopXList,
} from 'components/UI'
import { useCallback, useEffect, useState } from 'react'
import { ObjectOf } from 'helpers/Types'
import customerAnalytics from 'services/customerAnalytics.service'
import { CalendarDateRangeType } from 'components/Forms/DateRangePicker'
import { subMonths } from 'date-fns'
import { DateRangePicker, IconButton } from 'components/Forms'
import { COLORS } from 'styles'
import { Tune as TuneIcon } from '@mui/icons-material'
import { selectChartSettings } from 'redux/CustomerAnalytics/customerAnalytics.selectors'
import { useAppSelector } from 'redux/hooks'
import { formatDateForApi } from 'helpers/Methods'
import { CHART_TYPES } from 'helpers/Constants'
import TopStats from './TopStats'
import ChartSettings from './ChartSettings'

export interface GraphData {
  xAxisLabels: string[]
  series: SeriesData[]
}

const defaultChartData = {
  xAxisLabels: [],
  series: [],
}

export default function CustomerAnalytics() {
  const [topStats, setTopStats] = useState({})
  const [graphData, setGraphData] = useState<ObjectOf<GraphData>>({})
  const [dateRange, setDateRange] = useState<CalendarDateRangeType>([subMonths(new Date(), 1), new Date()])
  const [chartSettingOpen, setChartSettingOpen] = useState(false)
  const chartSettings = useAppSelector(selectChartSettings)
  const [charts, setCharts] = useState([])

  const getData = useCallback(async () => {
    //@ts-ignore
    const { stats, chart } = await customerAnalytics.getAnalyticsData({
      params: {
        startDate: formatDateForApi(dateRange[0]),
        endDate: formatDateForApi(dateRange[1]),
      },
    })
    setTopStats({ ...stats })
    setGraphData({
      ...chart,
    })
  }, [dateRange])

  // fetch data
  useEffect(() => {
    getData()
  }, [getData, dateRange])

  // chart config
  useEffect(() => {
    setCharts(
      chartSettings
        .filter((chart) => !!chart.visible)
        .map((chart) => {
          let Component

          switch (chart.type) {
            case CHART_TYPES.COLUMN: {
              Component = ColumnChartWidget
              break
            }

            case CHART_TYPES.PIE: {
              Component = PieChartWidget
              break
            }

            case CHART_TYPES.SCATTER: {
              Component = ScatterChartWidget
              break
            }

            case CHART_TYPES.SPLINE: {
              Component = SplineChartWidget
              break
            }

            case CHART_TYPES.TOP_X_LIST: {
              Component = TopXList
              break
            }

            default: {
              Component = ColumnChartWidget
              break
            }
          }

          return {
            ...chart,
            Component,
          }
        }),
    )
  }, [chartSettings])

  return (
    <Box>
      {/* Heading */}
      <Box sx={customerAnalyticsStyles.pageHeaderWrapper}>
        <PageTitle>Customer Analytics</PageTitle>

        {/* Toolbar */}
        <Box sx={customerAnalyticsStyles.toolsWrapper}>
          <DateRangePicker
            value={dateRange}
            onChange={(range) => {
              setDateRange(range)
            }}
            disableFutureDates
            // displayValue
            customDisplayCalendarIcon
          />
          <IconButton
            sx={customerAnalyticsStyles.toolIconButton}
            onClick={() => {
              setChartSettingOpen(true)
            }}
          >
            <TuneIcon sx={customerAnalyticsStyles.toolIcon} />
          </IconButton>
        </Box>
      </Box>

      {/* Charts */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopStats data={topStats} />
        </Grid>
        {charts.map((chart) => (
          <Grid item xs={12} sm={12} md={6} key={chart.dataKey}>
            <chart.Component {...chart.props} {...(graphData[chart.dataKey] ?? { ...defaultChartData })} />
          </Grid>
        ))}
      </Grid>

      <ChartSettings
        open={chartSettingOpen}
        onClose={() => {
          setChartSettingOpen(false)
        }}
      />
    </Box>
  )
}

interface CustomerAnalyticsStyles {
  pageHeaderWrapper: SxProps
  toolsWrapper: SxProps
  toolIconButton: SxProps
  toolIcon: SxProps
}

const customerAnalyticsStyles: CustomerAnalyticsStyles = {
  pageHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolsWrapper: {
    display: 'flex',
    padding: '0 10px',
    border: `1px solid ${COLORS.CADET_BLUE}`,
    borderRadius: '50px',
  },
  toolIconButton: {
    p: 0.5,
  },
  toolIcon: {
    color: COLORS.BLACK,
    '&:hover': { color: COLORS.PRIMARY_DARK },
  },
}
