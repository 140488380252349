import { useSnackbar, VariantType } from 'notistack'
import { useCallback, useMemo } from 'react'

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar()

  const show = useCallback(
    (message: string, variant: VariantType = 'default') => {
      enqueueSnackbar(message, {
        variant,
        persist: false,
      })
    },
    [enqueueSnackbar],
  )

  const success = useCallback(
    (message: string) => {
      show(message, 'success')
    },
    [show],
  )

  const warning = useCallback(
    (message: string) => {
      show(message, 'warning')
    },
    [show],
  )

  const info = useCallback(
    (message: string) => {
      show(message, 'info')
    },
    [show],
  )
  const error = useCallback(
    (message?: string) => {
      show(message ?? 'Error Processing your request!', 'error')
    },
    [show],
  )

  const methods = useMemo(
    () => ({
      show,
      success,
      warning,
      info,
      error,
    }),
    [error, info, show, success, warning],
  )

  return methods
}

export default useToast
