import { SxProps } from '@mui/material'

interface HeaderStylesTypes {
  headerSection: SxProps
  headerSectionFull: SxProps
  toolbar: SxProps
  toolbarFull: SxProps
}

const headerStyles: HeaderStylesTypes = {
  headerSection: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(6px)',
    boxShadow: 'none',
    display: 'flex',
    '.bs-logo-sm': {
      height: 'auto',
      width: '150px',
      mr: '2px',
    },
  },
  headerSectionFull: {
    left: '64px',
    width: 'calc(100% - 64px)',
  },
  toolbar: {
    background: 'rgba(255, 255, 255, 0.88)',
    backdropFilter: 'blur(6px)',
    boxShadow: 'rgba(47, 43, 61, 0.12) 0px 2px 8px 0px',
    borderRadius: '10px',
    justifyContent: 'space-between',
    px: 2,
    mx: 3,
    mt: 2,
  },
  toolbarFull: {},
}

export default headerStyles
